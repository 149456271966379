import React, { useEffect, useState, useCallback } from "react";
import {
  Input,
  Button,
  Loader,
  Segment,
  Label,
  Dimmer,
  Grid,
  Icon,
  Header,
  Popup,
} from "semantic-ui-react";
import _ from "lodash";
import axios from "../../../../apis/dvdlocator";
import { useDispatch } from "react-redux";
import { addNotification } from "../../../../redux/actions/notificationActions";

function AddFamilyPopups({ editor }) {
  const dispatch = useDispatch();
  const [searching, setSearching] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [familyName, setFamilyName] = useState("");
  const [error, setError] = useState("");
  const [selectedName, setSelectedName] = useState("");
  const [creatingNew, setCreatingNew] = useState(false);
  const [search, setSearch] = useState("");
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [families, setFamilies] = useState([]);
  const [selectedFamilies, setSelectedFamilies] = useState([]);
  const debouncedSearch = useCallback(
    _.debounce((q) => updateSearchResults(q), 500),
    [families]
  );

  function updateSearchResults(term) {
    if (!term) return setResults(families.slice(0, 5));

    setResults(
      families.filter((item) =>
        item.family.toLowerCase().includes(term.toLowerCase())
      )
    );
  }

  const addFamilyHiddenFormInput = (familyId) => {
    const currHiddenInputValue = editor.field("custom_add_families").val();

    if (currHiddenInputValue === "") {
      editor
        .field("custom_add_families")
        .val(currHiddenInputValue.concat(familyId));
    } else {
      editor
        .field("custom_add_families")
        .val(currHiddenInputValue.concat(`,${familyId}`));
    }
  };

  const removeFamilyHiddenFormInput = (familyId) => {
    const currHiddenInputValue = editor.field("custom_add_families").val();

    if (currHiddenInputValue.includes(",")) {
      const isFirstElement =
        currHiddenInputValue.match(`,${familyId}`) === null;
      const replaceString = isFirstElement ? `${familyId},` : `,${familyId}`;

      editor
        .field("custom_add_families")
        .val(currHiddenInputValue.replace(replaceString, ""));
    } else {
      editor.field("custom_add_families").val("");
    }
  };

  const addFamily = (familyId, familyName) => {
    if (!selectedFamilies.some((fam) => fam.familyId === familyId)) {
      setSelectedFamilies((prev) => [...prev, { familyId, familyName }]);
      addFamilyHiddenFormInput(familyId);
    }

    setSearching(false);
    setSubmitting(false);
    setSelectedId(null);
    setSelectedName("");
  };

  const removeFamily = (familyId) => {
    removeFamilyHiddenFormInput(familyId);
    setSelectedFamilies((prev) =>
      prev.filter((fam) => fam.familyId !== familyId)
    );
  };

  const createNewFamily = (family) => {
    if (familyName.includes(","))
      return setError("Family name cannot include comma.");

    setSubmitting(true);
    axios
      .post(`/families`, { name: family })
      .then((res) => {
        const id = res.data.id;

        setFamilies((families) => [
          { id, family, member_count: 0, new: true },
          ...families,
        ]);
        setResults((prev) => [
          { id, family, member_count: 0, new: true },
          ...prev,
        ]);

        dispatch(
          addNotification({
            text: "Created new family!",
            icon: <Icon color="green" name="checkmark box" />,
          })
        );

        setFamilyName("");

        setCreatingNew();
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status === 409) {
            return setError("This family name already exists.");
          }
        }
        dispatch(
          addNotification({
            text: "Error creating new family.",
            icon: <Icon color="red" name="warning circle" />,
          })
        );
      })
      .finally(() => setSubmitting(false));
  };

  useEffect(() => {
    const loadFamilies = async () => {
      try {
        const res = await axios.get(`/families`);

        setFamilies(res.data);
        setResults(res.data.slice(0, 5));
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    };
    setLoading(true);
    loadFamilies();
  }, []);

  const ListItem = ({ familyId, family, memberCount, isNew }) => (
    <li
      onClick={() => {
        setSelectedId(familyId);
        setSelectedName(family);
      }}
      className={`hover-darken  ${isNew && "highlight-once"} ${
        selectedId === familyId && "selected"
      }`}
      style={{
        display: "block",
        margin: "12px 0px",
        padding: "6px",
      }}
    >
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <span>
          {family} {isNew && <i style={{ color: "gray" }}>(just added)</i>}
        </span>
        <span>({memberCount} items..)</span>
      </div>
    </li>
  );

  return (
    <div style={{ display: "flex", marginBottom: "16px" }}>
      <Popup
        onClose={() => setSearching(false)}
        open={searching}
        flowing
        style={{ width: "600px" }}
        position="bottom left"
        closeOnTriggerMouseLeave={false}
        trigger={
          <Button
            primary
            size="small"
            onClick={() => setSearching(true)}
            title="Add product to family upon creation"
          >
            Add To Family
          </Button>
        }
      >
        {creatingNew ? (
          <>
            <Popup.Header>Creating new family</Popup.Header>
            <Popup.Content>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: "32px",
                }}
              >
                {error && (
                  <span style={{ color: "red", marginBottom: "8px" }}>
                    {error}
                  </span>
                )}
                <Label>Enter Family Name</Label>
                <Input
                  placeholder="Family name"
                  size="big"
                  disabled={submitting}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      createNewFamily(familyName);
                    }
                  }}
                  onChange={(e) => {
                    if (error) setError("");
                    setFamilyName(e.target.value);
                  }}
                  value={familyName}
                />
              </div>

              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Button onClick={() => setCreatingNew(false)}>Cancel</Button>
                <Button
                  primary
                  onClick={() => createNewFamily(familyName)}
                  disabled={submitting}
                >
                  Create
                </Button>
              </div>
            </Popup.Content>
          </>
        ) : (
          <>
            <Popup.Header>
              <Grid columns={2}>
                <Grid.Column floated="left" verticalAlign="middle" width={12}>
                  <Header size="tiny">Add family to supply unit</Header>
                </Grid.Column>
                <Grid.Column floated="right" width={4}>
                  <Button
                    icon
                    labelPosition="left"
                    color="green"
                    floated="right"
                    title="Create new family"
                    onClick={() => setCreatingNew(true)}
                  >
                    <Icon name="plus square" />
                    New
                  </Button>
                </Grid.Column>
              </Grid>
            </Popup.Header>
            <Popup.Content>
              <Input
                placeholder="Search families.."
                size="large"
                onChange={(e) => {
                  setSearch(e.target.value);
                  // debouncing actual search filtering
                  debouncedSearch(e.target.value);
                }}
                value={search}
              />
              {loading ? (
                <Segment padded="very">
                  <Dimmer active inverted>
                    <Loader active inline="centered" content="Loading" />
                  </Dimmer>
                </Segment>
              ) : (
                <ul
                  style={{
                    backgroundColor: "#e7e9eb",
                    padding: "12px 6px",
                    maxHeight: "600px",
                    overflow: "scroll",
                  }}
                >
                  {results.map((family) => (
                    <ListItem
                      key={family.id}
                      familyId={family.id}
                      family={family.family}
                      memberCount={family.member_count}
                      isNew={family.new}
                    />
                  ))}
                </ul>
              )}

              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Button onClick={() => setSearching(false)}>Cancel</Button>

                <Button
                  disabled={!selectedId || submitting}
                  color="green"
                  onClick={() => {
                    setSubmitting(true);
                    setSearch("");
                    addFamily(selectedId, selectedName);
                  }}
                >
                  Add to supply unit
                </Button>
              </div>
            </Popup.Content>
          </>
        )}
      </Popup>
      {selectedFamilies.map((family) => (
        <Label key={family.familyId}>
          {family.familyName}{" "}
          <Icon
            name="delete"
            color="red"
            onClick={() => removeFamily(family.familyId)}
          />
        </Label>
      ))}
    </div>
  );
}

export default AddFamilyPopups;
