import React from "react";
import { Menu, Sidebar, Button, Container } from "semantic-ui-react";

import "./PricetoolSidebar.css";

// creates 2 custom semantic ui sidebars for pricetool (one on each side)
class PricetoolSidebar extends React.Component {
  state = { leftVisible: false, rightVisible: false, buttonShift: 0 };

  logContainer = React.createRef();

  componentDidUpdate() {
    const sidebarRef = this.logContainer.current;

    if (
      sidebarRef.ref.current.scrollHeight > sidebarRef.ref.current.offsetHeight
    ) {
      this.props.clearSidebar();
    }
  }

  checkedStores = {
    walmartca: 1,
    importcdsebay: 1,
  };

  renderOpenLinks(stores) {
    if (this.props.openPricetoolLink) {
      return stores.map((store) => {
        let storeName = store;
        if (store === "ebayv1") {
          storeName = "Ebay Used and New";
        }
        return (
          <Menu.Item
            as="a"
            onClick={() => this.props.openPricetoolLink(store)}
            key={store}
          >
            <input
              type="image"
              alt={store}
              title={`Open ${storeName}`}
              src={require(`../../../assets/icons/${store}.png`)}
              height={30}
            />
            {this.checkedStores[store] && (
              <i className="check square outline icon"></i>
            )}
          </Menu.Item>
        );
      });
    } else {
      return <div>Loading...</div>;
    }
  }

  render() {
    return (
      <Sidebar.Pushable>
        <Sidebar
          as={Menu}
          animation="push"
          icon="labeled"
          direction="left"
          vertical
          visible={this.props.leftSidebarVisible}
          width="thin"
          onMouseLeave={() => {
            this.props.closeLeftSidebar();
          }}
        >
          {this.renderOpenLinks([
            "dealsareus",
            "alliance",
            "walmart",
            "amazon",
            "ebayv1",
            "target",
            "ebayblowit",
            "gruv",
            "rarewaves",
            "importcds",
            "ccvideo",
            "rightstuf",
            "deepdiscount",
            "wowhd",
            "blowitoutahere",
            "importcdsebay",
            "walmartca",
            "amazonca",
            "amazonuk",
            "bestbuy",
            "chapters",
            "bookdepo",
            "bullmoose",
            "mila",
            "couch potato",
            "camel",
            "barnesandnoble",
          ])}
        </Sidebar>

        <Sidebar
          ref={this.logContainer}
          as={Container}
          animation="overlay"
          direction="right"
          vertical="true"
          visible={this.props.rightSidebarVisible}
          width="wide"
        >
          <Button
            size="tiny"
            inverted
            style={{
              position: "absolute",
              top: "1rem",
              right: "1rem",
              zIndex: 1000,
              margin: 0,
            }}
            onClick={this.props.clearSidebar}
          >
            Clear
          </Button>
          {this.props.content}
        </Sidebar>

        <Sidebar.Pusher>{this.props.children}</Sidebar.Pusher>
      </Sidebar.Pushable>
    );
  }
}

export default PricetoolSidebar;
