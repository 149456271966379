import "tippy.js/dist/tippy.css";
import { renderDate } from "../../formatting/dateFormat";

export const masterColumnSet = [
  {
    label:
      '<Tooltip title = "UPC(Universal Product Code)/ SKU (Stock Keeping Unit)" position = "top" trigger = "mouseenter"><p>UPC</p></Tooltip>',
    name: "damage.upc",
    data: "damage.upc",
    title:
      '<Tooltip title = "UPC(Universal Product Code)/ SKU (Stock Keeping Unit)" position = "left start" trigger = "click"><p>UPC</p></Tooltip>',
  },
  {
    label:
      '<Tooltip title = "Where the damaged inventory can be found in the warehouse" position = "bottom" trigger = "click"><p>Damage Shelf</p></Tooltip>',
    name: "damage.damage_shelf",
    data: "damage.damage_shelf",
    title:
      '<Tooltip title = "Where the damaged inventory can be found in the warehouse" position = "bottom" trigger = "click"><p>Damage Shelf</p></Tooltip>',
  },
  {
    label:
      '<Tooltip title = "Yom \'s title for item (not Amazon \'s)" position = "botom" trigger = "click"><p>Title</p></Tooltip>',
    name: "supply.supply_title",
    type: "readonly",
    attr: {
      disabled: true,
    },
    data: "supply.supply_title",
    title:
      '<Tooltip title = "Yom \'s title for item (not Amazon \'s)" position = "bottom" trigger = "click"><p>Title</p></Tooltip>',
  },
  {
    label:
      '<Tooltip title = "X: The product has been removed from its location in our facility and moved to a new location in the next step of the work order \n\nY: The item was unable to be located at its assigned location" position = "bottom" trigger = "click"><p>Status</p></Tooltip>',
    name: "damage.status",
    data: "damage.status",
    title:
      '<Tooltip title = "X: The product has been removed from its location in our facility and moved to a new location in the next step of the work order \n\nY: The item was unable to be located at its assigned location" position = "bottom" trigger = "click"><p>Status</p></Tooltip>',
    type: "readonly",
    attr: {
      disabled: true,
    },
  },
  {
    label:
      '<Tooltip title = "DamageQty" position = "bottom" trigger = "click"><p>DamageQty</p></Tooltip>',
    name: "damage.damage_qty",
    type: "readonly",
    attr: {
      disabled: true,
    },
    data: "damage.damage_qty",
    title:
      '<Tooltip title = "DamageQty" position = "bottom" trigger = "click"><p>DamageQty</p></Tooltip>',
  },
  {
    label:
      '<Tooltip title =  "The damage type assigned to the product\n1: Can only be repaired\n2: Can be repaired or returned\n3: Can only be returned"  position = "bottom" trigger = "click"><p>Damage Reason</p></Tooltip>',
    name: "damage.damage_reason",
    data: "damage.damage_reason",
    title:
      '<Tooltip title =  "The damage type assigned to the product\n1: Can only be repaired\n2: Can be repaired or returned\n3: Can only be returned"  position = "bottom" trigger = "click"><p>Damage Reason</p></Tooltip>',
    render: function (data) {
      let return_string = "<span class='inline-blacklisted' title='";

      if (data === 1) {
        return_string += "Dislodged Disc and/or Unsealed";
      } else if (data === 2) {
        return_string += "Damaged\n- DVD Case < 1”\n- Single CD Case";
      } else if (data === 3) {
        return_string +=
          "Damaged\n- Paper / Cardboard\n- DVD Case > 1”\n- BluRay Case\n- Double CD Case";
      } else if (data === 4) {
        return_string += "Missing or Cracked Disc / Wrong Item";
      } else if (data === 5) {
        return_string += "Misc.";
      }

      return_string +=
        "' style='height:100%; width:100%;display:block;overflow:auto'>" +
        data +
        "</span>";

      return return_string;
    },
  },
  {
    label:
      '<Tooltip title = "ReturnTracking" position = "bottom" trigger = "click"><p>ReturnTracking</p></Tooltip>',
    name: "damage.return_tracking",
    data: "damage.return_tracking",
    title:
      '<Tooltip title = "ReturnTracking" position = "bottom" trigger = "click"><p>ReturnTracking</p></Tooltip>',
  },
  {
    label:
      '<Tooltip title = "The date the product was reordered from a supplier (Manually added by Analyst)" position = "bottom" trigger = "click"><p>Date Reordered</p></Tooltip>',
    name: "damage.date_reordered",
    data: "damage.date_reordered",
    title:
      '<Tooltip title = "The date the product was reordered from a supplier (Manually added by Analyst)" position = "bottom" trigger = "click"><p>Date Reordered</p></Tooltip>',
    render: renderDate,
  },
  {
    label:
      '<Tooltip title = "The date the product was scanned in as damaged" position = "bottom" trigger = "click"><p>Damage Date</p></Tooltip>',
    name: "damage.received",
    data: "damage.received",
    title:
      '<Tooltip title = "The date the product was scanned in as damaged" position = "bottom" trigger = "click"><p>Damage Date</p></Tooltip>',
    render: renderDate,
  },
  {
    label:
      '<Tooltip title ="The work order associated with the product\n.repair: This product must be repaired\n.return: This product must be returned" position = "bottom" trigger = "click"><p>Work Order</p></Tooltip>',
    name: "damage.work_order",
    data: "damage.work_order",
    title:
      '<Tooltip title ="The work order associated with the product\n.repair: This product must be repaired\n.return: This product must be returned" position = "bottom" trigger = "click"><p>Work Order</p></Tooltip>',
    type: "readonly",
    attr: {
      disabled: true,
    },
  },
  {
    label:
      '<Tooltip title = "Damage Note" position = "bottom" trigger = "click"><p>Damage Note</p></Tooltip>',
    name: "damage.note",
    data: "damage.note",
    title:
      '<Tooltip title = "Damage Note" position = "bottom" trigger = "click"><p>Damage Note</p></Tooltip>',
  },
];
