import "tippy.js/dist/tippy.css";
import { renderDate } from "../../formatting/dateFormat";
import { hourlyOrDailyDateDifference } from "../../formatting/dateDifferHoursDaily";
import {
  BUYBOX_CONDITION_IMAGE,
  BUYBOX_SELLER_IMAGE,
  BUYBOX_SHIP_SPEED_IMAGE,
} from "../../formatting/buyboxInfo";
import BigNumber from "bignumber.js";
import { formatToPoundsOunces } from "../../formatting/weightFormat";
import { renderTitle } from "../../formatting/title";

export const masterProductColumnSet = [
  {
    data: "product.product_id",
    name: "product.product_id",
    type: "hidden",
    visible: false,
    excludeFromGeneralSearch: true,
  },
  {
    label:
      '<Tooltip title = "User designated sort position" position = "bottom" trigger = "click"><p>Sort</p></Tooltip>',
    name: "product.sort",
    data: "product.sort",
    title:
      '<Tooltip title = "User designated sort position" position = "bottom" trigger = "click"><p>Sort</p></Tooltip>',
  },
  {
    label:
      '<Tooltip title = "MSKU (Merchant Stock Keeping Unit)" position = "bottom" trigger = "click"><p>MSKU</p></Tooltip>',
    name: "product.msku",
    data: "product.msku",
    title:
      '<Tooltip title = "MSKU (Merchant Stock Keeping Unit)" position = "bottom" trigger = "click"><p>MSKU</p></Tooltip>',
  },
  {
    label:
      '<Tooltip title = "Product ASIN (Amazon Standard Identification Number)" position = "bottom" trigger = "click"><p>Product ASIN</p></Tooltip>',
    name: "product.product_asin",
    data: "product.product_asin",
    title:
      '<Tooltip title = "Product ASIN (Amazon Standard Identification Number)" position = "bottom" trigger = "click"><p>Product ASIN</p></Tooltip>',
  },
  {
    label:
      '<Tooltip title = "Image of product" position = "bottom" trigger = "click"><p>Product Image</p></Tooltip>',
    name: "product.product_image",
    data: "product.product_image",
    title:
      '<Tooltip title = "Image of product" position = "bottom" trigger = "click"><p>Product Image</p></Tooltip>',
  },
  {
    label:
      '<Tooltip title = "Yom \'s title for item (not Amazon \'s)" position = "bottom" trigger = "click"><p>Product Title</p></Tooltip>',
    name: "product.product_title",
    data: "product.product_title",
    title:
      '<Tooltip title = "Yom \'s title for item (not Amazon \'s)" position = "bottom" trigger = "click"><p>Product Title</p></Tooltip>',
    render: (data, type, row) => renderTitle(data),
  },
  {
    name: "product_families_view.families",
    label:
      '<Tooltip title = "Product families this product is a member of." position = "bottom" trigger = "click"><p>Product Families</p></Tooltip>',
    attr: {
      disabled: true,
    },
    data: "product_families_view.families",
    title:
      '<Tooltip title = "Product families this product is a member of." position = "bottom" trigger = "click"><p>Product Families</p></Tooltip>',
    render: function (data, type, row) {
      if (!data) return null;
      if (data.length < 78) return data;
      return `${data.substring(0, 75)}...`;
    },
  },
  {
    label:
      '<Tooltip title = "Pieces required to assemble this product" position = "bottom" trigger = "click"><p>Parts Required</p></Tooltip>',
    name: "product.assembly_part_number",
    data: "product.assembly_part_number",
    title:
      '<Tooltip title = "Pieces required to assemble this product" position = "bottom" trigger = "click"><p>Parts Required</p></Tooltip>',
  },
  {
    label:
      '<Tooltip title = "Weight of the product in lbs and ounces" position = "bottom" trigger = "click"><p>Weight</p></Tooltip>',
    name: "product.packaged_weight_ounces",
    data: "product.packaged_weight_ounces",
    title:
      '<Tooltip title = "Weight of the product in lbs and ounces" position = "bottom" trigger = "click"><p>Weight</p></Tooltip>',
    attr: {
      disabled: true,
    },
    excludeFromGeneralSearch: true,
    render: function (data, type, row) {
      return formatToPoundsOunces(data);
    },
  },
  {
    label:
      '<Tooltip title = "How many components are in this product" position = "bottom" trigger = "click"><p>Component Qty</p></Tooltip>',
    name: "product.component_count",
    data: "product.component_count",
    title:
      '<Tooltip title = "How many components are in this product" position = "bottom" trigger = "click"><p>Component Qty</p></Tooltip>',
    excludeFromGeneralSearch: true,
  },
  {
    label:
      '<Tooltip title = "Custom user inputted amount to adjust base handling cost" position = "bottom" trigger = "click"><p>Handling Cost Adjustment</p></Tooltip>',
    name: "product.yom_handling_cost_adjustment",
    data: "product.yom_handling_cost_adjustment",
    title:
      '<Tooltip title = "Custom user inputted amount to adjust base handling cost" position = "bottom" trigger = "click"><p>Handling Cost Adjustment</p></Tooltip>',
    excludeFromGeneralSearch: true,
  },
  {
    label:
      '<Tooltip title = "Genre" position = "bottom" trigger = "click"><p>Genre</p></Tooltip>',
    name: "product.genre",
    data: "product.genre",
    title:
      '<Tooltip title = "Genre" position = "bottom" trigger = "click"><p>Genre</p></Tooltip>',
    excludeFromGeneralSearch: true,
  },
  {
    name: "media_types[].id",
    label: "Media Types",
    data: "media_types[].id",
    searchable: false,
    orderable: false,
    excludeFromGeneralSearch: true,
    type: "select",
    multiple: true,
    visible: false, // using view column below for display
  },
  {
    name: "product_media_types_view.media_types",
    title:
      '<Tooltip title = "The media types in this product (Vinyl, Book, CD, DVD, Bluray, 4K)" position = "bottom" trigger = "click"><p>Media Types</p></Tooltip>',
    data: "product_media_types_view.media_types",
    // using mjoin field above for edit form
  },

  {
    label:
      '<Tooltip title = "Whether or not the product has been retired." position = "bottom" trigger = "click"><p>Retired</p></Tooltip>',
    name: "product.retired",
    attr: {
      disabled: true,
    },
    data: "product.retired",
    title:
      '<Tooltip title = "Whether or not the product has been retired." position = "bottom" trigger = "click"><p>Retired</p></Tooltip>',
    render: function (data, type, row) {
      if (data === 0) return "No";
      if (!data) return null;
      return "Yes";
    },
    excludeFromGeneralSearch: true,
  },
  {
    label:
      '<Tooltip title = "When the product was retired." position = "bottom" trigger = "click"><p>Retired Timestamp</p></Tooltip>',
    name: "product.retired_timestamp",
    attr: {
      disabled: true,
    },
    data: "product.retired_timestamp",
    title:
      '<Tooltip title = "When the product was retired." position = "bottom" trigger = "click"><p>Retired Timestamp</p></Tooltip>',
    render: renderDate,
    excludeFromGeneralSearch: true,
  },
  {
    label:
      '<Tooltip title = "Weight and Dimensions of a collection" position = "bottom" trigger = "click"><p>Ship Wt</p></Tooltip>',
    name: "product.ship_weight",
    data: "product.ship_weight",
    title:
      '<Tooltip title = "Weight and Dimensions of a collection" position = "bottom" trigger = "click"><p>Ship Wt</p></Tooltip>',
  },
  {
    label:
      '<Tooltip title = "Identifier for parents of a collection (.amx / .rmx / .mx / .bmx) " position = "bottom" trigger = "click"><p>Part #</p></Tooltip>',
    name: "product.part_number",
    data: "product.part_number",
    title:
      '<Tooltip title = "Identifier for parents of a collection (.amx / .rmx / .mx / .bmx) " position = "bottom" trigger = "click"><p>Part #</p></Tooltip>',
  },

  {
    label:
      '<Tooltip title = "The artcard number for this collection" position = "bottom" trigger = "click"><p>Card#</p></Tooltip>',
    name: "product.card_number",
    data: "product.card_number",
    title:
      '<Tooltip title = "The artcard number for this collection" position = "bottom" trigger = "click"><p>Card#</p></Tooltip>',
  },
  {
    name: "product.total_sales",
    label:
      '<Tooltip title =  "The total units of this UPC sold on Amazon over the product\'s lifetime." position =  "bottom " trigger =  "click "><p>Total UPC Sales</p></Tooltip>',
    attr: {
      disabled: true,
    },
    data: "product.total_sales",
    title:
      '<Tooltip title =  "The total units of this UPC sold on Amazon over the product\'s lifetime." position =  "bottom " trigger =  "click "><p>Total UPC Sales</p></Tooltip>',
    excludeFromGeneralSearch: true,
  },
  {
    name: "product.90_day_sales",
    label:
      '<Tooltip title =  "Units of this UPC sold on Amazon over the past 90 days." position =  "bottom " trigger =  "click "><p>90 Day UPC Sales</p></Tooltip>',
    attr: {
      disabled: true,
    },
    data: "product.90_day_sales",
    title:
      '<Tooltip title =  "Units of this UPC sold on Amazon over the past 90 days." position =  "bottom " trigger =  "click "><p>90 Day UPC Sales</p></Tooltip>',
    excludeFromGeneralSearch: true,
  },
  {
    name: "product.30_day_sales",
    label:
      '<Tooltip title =  "Units of this UPC sold on Amazon over the past 30 days." position =  "bottom " trigger =  "click "><p>30 Day UPC Sales</p></Tooltip>',
    attr: {
      disabled: true,
    },
    data: "product.30_day_sales",
    title:
      '<Tooltip title =  "Units of this UPC sold on Amazon over the past 30 days." position =  "bottom " trigger =  "click "><p>30 Day UPC Sales</p></Tooltip>',
    excludeFromGeneralSearch: true,
  },
  {
    name: "product.7_day_sales",
    label:
      '<Tooltip title =  "Units of this UPC sold on Amazon over the past 7 days." position =  "bottom " trigger =  "click "><p>7 Day UPC Sales</p></Tooltip>',
    attr: {
      disabled: true,
    },
    data: "product.7_day_sales",
    title:
      '<Tooltip title =  "Units of this UPC sold on Amazon over the past 7 days." position =  "bottom " trigger =  "click "><p>7 Day UPC Sales</p></Tooltip>',
    excludeFromGeneralSearch: true,
  },
  {
    label:
      '<Tooltip title = "Quantity of product available to ship" position = "bottom" trigger = "click"><p>ATS</p></Tooltip>',
    name: "product.ats",
    attr: {
      disabled: true,
    },
    data: "product.ats",
    title:
      '<Tooltip title = "Quantity of product available to ship" position = "bottom" trigger = "click"><p>ATS</p></Tooltip>',
    render: function (data, type, row) {
      if (data === 999999) return "Misthreading error.";
      if (data === 999998) return "Inventory error.";
      return data;
    },
    excludeFromGeneralSearch: true,
  },
  {
    label:
      '<Tooltip title = "Timestamp for when the ATS value last increased" position = "bottom" trigger = "click"><p>ATS Increased</p></Tooltip>',
    name: "product.ats_last_increase_timestamp",
    attr: {
      disabled: true,
    },
    data: "product.ats_last_increase_timestamp",
    title:
      '<Tooltip title = "Timestamp for when the ATS value last increased" position = "bottom" trigger = "click"><p>ATS Increased</p></Tooltip>',
    render: renderDate,
  },
  {
    label:
      '<Tooltip title = "Master Analyst Inventory. Current inventory on hand + Inventory On Order (ONO) for all UPCs required to fulfill this row." position = "bottom" trigger = "click"><p>MAI</p></Tooltip>',
    name: "product.master_analyst_inv",
    attr: {
      disabled: true,
    },
    data: "product.master_analyst_inv",
    title:
      '<Tooltip title = "Master Analyst Inventory. Current inventory on hand + Inventory On Order (ONO) for all UPCs required to fulfill this row." position = "bottom" trigger = "click"><p>MAI</p></Tooltip>',
    render: function (data, type, row) {
      if (data === 999999) return "Misthreading error.";
      if (data === 999998) return "Inventory error.";
      return data;
    },
    excludeFromGeneralSearch: true,
  },
  {
    label:
      '<Tooltip title = "The availability of supply inventory needed to fulfill this selling option from our distributors. IS=In stock, BO=Back Order, OP=Out of Print" position = "bottom" trigger = "click"><p>Restock</p></Tooltip>',
    name: "product.restock_status_calculated",
    attr: {
      disabled: true,
    },
    data: "product.restock_status_calculated",
    title:
      '<Tooltip title = "The availability of supply inventory needed to fulfill this selling option from our distributors. IS=In stock, BO=Back Order, OP=Out of Print" position = "bottom" trigger = "click"><p>Restock</p></Tooltip>',
    render: function (data, type, row) {
      if (data === "999999") return "Misthreading error.";
      if (data === "OP")
        return `<span style="color:#CC0000;font-weight:700;font-size:17px;">OP</span>`;
      if (data === "BO")
        return `<span style="color:#0066FF;font-weight:700;font-size:17px;">BO</span>`;
      if (data === "IS")
        return `<span style="color:#339933;font-weight:700;font-size:17px;">IS</span>`;

      return null;
    },
  },
  {
    name: "product.weeks_until_depleted",
    label:
      '<Tooltip title = "Weeks to Depletion. How many weeks of stock do we have on hand or purchased to fulfill this selling option, based on the last 30 days of total page sales?" position = "bottom" trigger = "click"><p>WTD</p></Tooltip>',
    attr: {
      disabled: true,
    },
    data: "product.weeks_until_depleted",
    title:
      '<Tooltip title = "Weeks to Depletion. How many weeks of stock do we have on hand or purchased to fulfill this selling option, based on the last 30 days of total page sales?" position = "bottom" trigger = "click"><p>WTD</p></Tooltip>',
    render: function (data, type, row) {
      if (data === 9999999.9) return "No sales.";
      if (data === 9999999.8) return "Inventory error.";
      return data;
    },
    excludeFromGeneralSearch: true,
  },

  {
    name: "product.amazon_stock_weeks_until_depleted",
    label:
      '<Tooltip title = "How many weeks of stock do we have in Amazon’s network to fulfill this selling option, based on the last 30 days of total page sales?" position = "bottom" trigger = "click"><p>WTD AF</p></Tooltip>',
    attr: {
      disabled: true,
    },
    data: "product.amazon_stock_weeks_until_depleted",
    title:
      '<Tooltip title = "How many weeks of stock do we have in Amazon’s network to fulfill this selling option, based on the last 30 days of total page sales?" position = "bottom" trigger = "click"><p>WTD AF</p></Tooltip>',
    render: function (data, type, row) {
      if (data === 9999999.9) return "No sales.";
      if (data === 9999999.8) return "Inventory error.";
      return data;
    },
    excludeFromGeneralSearch: true,
  },
  {
    label:
      '<Tooltip title = "Master replacement cost of goods - purchasable. The lowest possible cost of purchasing all of the supply inventory required to fulfill this selling option, from any suppliers." position = "bottom" trigger = "click"><p>MRCOG-P</p></Tooltip>',
    name: "product.replacement_cost",
    attr: {
      disabled: true,
    },
    title:
      '<Tooltip title = "Master replacement cost of goods - purchasable. The lowest possible cost of purchasing all of the supply inventory required to fulfill this selling option, from any suppliers." position = "bottom" trigger = "click"><p>MRCOG-P</p></Tooltip>',
    data: "product.replacement_cost",
    render: function (data, type, row) {
      if (!data) return null;
      if (data === 999999) return "Misthreading error.";
      return data.toLocaleString("en-US", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      });
    },
    excludeFromGeneralSearch: true,
  },
  {
    label:
      '<Tooltip title = "The lowest cost of purchasing all of the supply inventory required to fulfill this selling option, which is in stock with a distributor supplier." position = "bottom" trigger = "click"><p>MRCOG-IS</p></Tooltip>',
    name: "product.replacement_cost_dist",
    attr: {
      disabled: true,
    },
    title:
      '<Tooltip title = "The lowest cost of purchasing all of the supply inventory required to fulfill this selling option, which is in stock with a distributor supplier." position = "bottom" trigger = "click"><p>MRCOG-IS</p></Tooltip>',
    data: "product.replacement_cost_dist",
    render: function (data, type, row) {
      if (!data) return null;
      if (data === 999999) return "Misthreading error.";
      return data.toLocaleString("en-US", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      });
    },
    excludeFromGeneralSearch: true,
  },
  {
    label:
      '<Tooltip title = "The sum of the average cost of all in-stock components needed to fulfill this collection." position = "bottom" trigger = "click"><p>MAVCG</p></Tooltip>',
    name: "product.master_avg_cost_of_goods",
    attr: {
      disabled: true,
    },
    title:
      '<Tooltip title = "The sum of the average cost of all in-stock components needed to fulfill this collection." position = "bottom" trigger = "click"><p>MAVCG</p></Tooltip>',
    data: "product.master_avg_cost_of_goods",
    render: function (data, type, row) {
      if (!data) return null;
      if (data === 999999) return "Misthreading error.";
      return data.toLocaleString("en-US", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      });
    },
    excludeFromGeneralSearch: true,
  },
  {
    label:
      '<Tooltip title = "Master On Order. Current inventory On Order (ONO) for all UPCs required to fulfill this row." position = "bottom" trigger = "click"><p>MONO</p></Tooltip>',
    name: "product.master_quantity_on_order",
    attr: {
      disabled: true,
    },
    title:
      '<Tooltip title = "Master On Order. Current inventory On Order (ONO) for all UPCs required to fulfill this row." position = "bottom" trigger = "click"><p>MONO</p></Tooltip>',
    data: "product.master_quantity_on_order",
    render: function (data, type, row) {
      if (data === 999999) return "Misthreading error.";
      if (data === 999998) return "Inventory error.";
      return data;
    },
    excludeFromGeneralSearch: true,
  },
  {
    name: "product_listings.merchant_available",
    label:
      '<Tooltip title =  "Is there a merchant listing available for the ASIN of this row?" position =  "bottom " trigger =  "click "><p>Assoc</p></Tooltip>',
    attr: {
      disabled: true,
    },
    data: "product_listings.merchant_available",
    title:
      '<Tooltip title =  "Is there a merchant listing available for the ASIN of this row?" position =  "bottom " trigger =  "click "><p>Assoc</p></Tooltip>',
    render: function (data, type, row) {
      if (data === 0) return "No";
      if (!data) return null;
      return "Yes";
    },
  },
  {
    name: "product_listings.purchasable_stock",
    label:
      '<Tooltip title =  "The total purchasable units for the ASIN\'s listing page." position =  "bottom " trigger =  "click "><p>Page Stock</p></Tooltip>',
    attr: {
      disabled: true,
    },
    data: "product_listings.purchasable_stock",
    title:
      '<Tooltip title =  "The total purchasable units for the ASIN\'s listing page." position =  "bottom " trigger =  "click "><p>Page Stock</p></Tooltip>',
    excludeFromGeneralSearch: true,
  },
  {
    name: "product_listings.30_day_profit",
    label:
      '<Tooltip title =  "The total profit of this ASIN’s last 30 day sales." position =  "bottom " trigger =  "click "><p>30 Day Profit</p></Tooltip>',
    attr: {
      disabled: true,
    },
    data: "product_listings.30_day_profit",
    title:
      '<Tooltip title =  "The total profit of this ASIN’s last 30 day sales." position =  "bottom " trigger =  "click "><p>30 Day Profit</p></Tooltip>',
    render: function (data, type, row) {
      if (!data) return null;

      const numericUsd = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(data);

      return numericUsd;
    },
  },
  {
    name: "product.page_sales_365_90_30_7",
    label:
      '<Tooltip title =  "Page sales in the following format: 365 - 90 - 30 - 7." position =  "bottom " trigger =  "click "><p>All Page Sales</p></Tooltip>',
    attr: {
      disabled: true,
    },
    data: "product.page_sales_365_90_30_7",
    title:
      '<Tooltip title =  "Page sales in the following format: 365 - 90 - 30 - 7." position =  "bottom " trigger =  "click "><p>All Page Sales</p></Tooltip>',
    excludeFromGeneralSearch: true,
  },
  {
    name: "product_listings.total_sales",
    label:
      '<Tooltip title =  "The lifetime aggregate sales for YOM\'s products on this ASIN listing page." position =  "bottom " trigger =  "click "><p>Total Page Sales</p></Tooltip>',
    attr: {
      disabled: true,
    },
    data: "product_listings.total_sales",
    title:
      '<Tooltip title =  "The lifetime aggregate sales for YOM\'s products on this ASIN listing page." position =  "bottom " trigger =  "click "><p>Total Page Sales</p></Tooltip>',
    excludeFromGeneralSearch: true,
  },
  {
    name: "product_listings.365_day_sales",
    label:
      '<Tooltip title =  "Aggregate sales for YOM\'s products on this ASIN listing page over the past 365 days." position =  "bottom " trigger =  "click "><p>365 Day Page Sales</p></Tooltip>',
    attr: {
      disabled: true,
    },
    data: "product_listings.365_day_sales",
    title:
      '<Tooltip title =  "Aggregate sales for YOM\'s products on this ASIN listing page over the past 365 days." position =  "bottom " trigger =  "click "><p>365 Day Page Sales</p></Tooltip>',
    excludeFromGeneralSearch: true,
  },
  {
    name: "product_listings.90_day_sales",
    label:
      '<Tooltip title =  "Aggregate sales for YOM\'s products on this ASIN listing page over the past 90 days." position =  "bottom " trigger =  "click "><p>90 Day Page Sales</p></Tooltip>',
    attr: {
      disabled: true,
    },
    data: "product_listings.90_day_sales",
    title:
      '<Tooltip title =  "Aggregate sales for YOM\'s products on this ASIN listing page over the past 90 days." position =  "bottom " trigger =  "click "><p>90 Day Page Sales</p></Tooltip>',
    excludeFromGeneralSearch: true,
  },
  {
    name: "product_listings.30_day_sales",
    label:
      '<Tooltip title =  "Aggregate sales for YOM\'s products on this ASIN listing page over the past 30 days." position =  "bottom " trigger =  "click "><p>30 Day Page Sales</p></Tooltip>',
    attr: {
      disabled: true,
    },
    data: "product_listings.30_day_sales",
    title:
      '<Tooltip title =  "Aggregate sales for YOM\'s products on this ASIN listing page over the past 30 days." position =  "bottom " trigger =  "click "><p>30 Day Page Sales</p></Tooltip>',
    excludeFromGeneralSearch: true,
  },
  {
    name: "product_listings.7_day_sales",
    label:
      '<Tooltip title =  "Aggregate sales for YOM\'s products on this ASIN listing page over the past 7 days." position =  "bottom " trigger =  "click "><p>7 Day Page Sales</p></Tooltip>',
    attr: {
      disabled: true,
    },
    data: "product_listings.7_day_sales",
    title:
      '<Tooltip title =  "Aggregate sales for YOM\'s products on this ASIN listing page over the past 7 days." position =  "bottom " trigger =  "click "><p>7 Day Page Sales</p></Tooltip>',
    excludeFromGeneralSearch: true,
  },
  {
    name: "product_listings.open_date",
    label:
      '<Tooltip title =  "Date that this listing was created" position =  "bottom " trigger =  "click "><p>Listing Created Date</p></Tooltip>',
    attr: {
      disabled: true,
    },
    data: "product_listings.open_date",
    title:
      '<Tooltip title =  "Date that this listing was created" position =  "bottom " trigger =  "click "><p>Listing Created Date</p></Tooltip>',
    excludeFromGeneralSearch: true,
  },
  {
    name: "product.sales_rank",
    label:
      '<Tooltip title =  "Sales rank on Amazon\'s website" position =  "bottom " trigger =  "click "><p>Sales Rank</p></Tooltip>',
    attr: {
      disabled: true,
    },
    data: "product.sales_rank",
    title:
      '<Tooltip title =  "Sales rank on Amazon\'s website" position =  "bottom " trigger =  "click "><p>Sales Rank</p></Tooltip>',
    excludeFromGeneralSearch: true,
  },
  {
    name: "product.product_category",
    label:
      '<Tooltip title =  "" position =  "bottom " trigger =  "click "><p>Product Category</p></Tooltip>',
    attr: {
      disabled: true,
    },
    data: "product.product_category",
    title:
      '<Tooltip title =  "" position =  "bottom " trigger =  "click "><p>Product Category</p></Tooltip>',
  },
  {
    name: "product.buy_box_price",
    label:
      '<Tooltip title =  "The price that is currently listed under the buy box" position =  "bottom " trigger =  "click "><p>BuyBox</p></Tooltip>',
    attr: {
      disabled: true,
    },
    data: "product.buy_box_price",
    title:
      '<Tooltip title =  "The price that is currently listed under the buy box" position =  "bottom " trigger =  "click "><p>BuyBox</p></Tooltip>',
    render: function (data, type, row) {
      if (!data) return null;

      const numericUsd = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(data);

      return numericUsd;
    },
    excludeFromGeneralSearch: true,
  },
  {
    name: "product.buy_box_info",
    label:
      "<div title='Information about the buybox seller, ship time, and various other listing conditions.'>Buy Box Info</div>",
    attr: {
      disabled: true,
    },
    data: "product.buy_box_info",
    title: "<div id='buybox-info-container'>Buy Box Info</div>",
    render: function (data, type, row) {
      if (!data) return null;

      const updatedAt = renderDate(row.product.buy_box_updated_timestamp);

      data = data.toLowerCase();

      if (!data.includes("-") || data.includes("ineligible")) {
        return `<img src=${BUYBOX_CONDITION_IMAGE[data]} alt='${data}' title='${data}\n\nUpdated: ${updatedAt}' />`;
      }

      if (data.includes("amazon")) {
        let [seller, shipSpeed] = data.split(" - ");

        if (seller === "temp out of stock") {
          [seller, shipSpeed] = [shipSpeed, seller]; // reversing order for TEMP OUT OF STOCK display
          return `<div><img src=${BUYBOX_CONDITION_IMAGE[shipSpeed]} alt='${shipSpeed}' title='${shipSpeed}\n\nUpdated: ${updatedAt}' /><img src=${BUYBOX_SELLER_IMAGE[seller]} alt='${seller}' title='${seller}\n\nUpdated: ${updatedAt}' /></div>`;
        }

        if (shipSpeed)
          return `<div><img src=${BUYBOX_SELLER_IMAGE[seller]} alt='${seller}' title='${seller}\n\nUpdated: ${updatedAt}' /><img src=${BUYBOX_SHIP_SPEED_IMAGE[shipSpeed]} alt='${shipSpeed}' title='${shipSpeed}\n\nUpdated: ${updatedAt}' /></div>`;

        return `<img src=${BUYBOX_SELLER_IMAGE[seller]} alt='${seller}' title='${seller}\n\nUpdated: ${updatedAt}' />`;
      }

      const [status, seller, shipSpeed] = data.split(" - ");

      let statusImg = BUYBOX_CONDITION_IMAGE[status];
      let shipSpeedImg = BUYBOX_SHIP_SPEED_IMAGE[shipSpeed];

      if (seller === "not yom") {
        if (shipSpeed)
          return `<div><img src=${statusImg} alt='${status}' title='${status} - not yom\n\nUpdated: ${updatedAt}' /><img src=${shipSpeedImg} alt='${shipSpeed}' title='${shipSpeed}\n\nUpdated: ${updatedAt}' /></div>`;

        return `<img src=${statusImg} alt='${status}' title='${status} - not yom\n\nUpdated: ${updatedAt}' />`;
      }

      let sellerImg = BUYBOX_SELLER_IMAGE[seller];

      if (shipSpeed)
        return `<div><img src=${statusImg} alt='${status}' title='${status}\n\nUpdated: ${updatedAt}' /><img src=${sellerImg} alt='${seller}' title='${seller}\n\nUpdated: ${updatedAt}' /><img src=${shipSpeedImg} alt='${shipSpeed}' title='${shipSpeed}\n\nUpdated: ${updatedAt}' /></div>`;

      return `<div><img src=${statusImg} alt='${status}' title='${status}\n\nUpdated: ${updatedAt}' /><img src=${sellerImg} alt='${seller}' title='${seller}\n\nUpdated: ${updatedAt}' /></div>`;
    },
  },
  {
    name: "product.buy_box_updated_timestamp",
    data: "product.buy_box_updated_timestamp",
    visible: false,
  },
  {
    attr: {
      disabled: true,
    },
    title: "<div id='buybox-markup-container'>Buybox Markup</div>",
    label:
      '<Tooltip title = "Percentage markup/markdown relative to children/equivalent products" position = "bottom" trigger = "click"><p>Buybox Markup</p></Tooltip>',

    name: "product.buy_box_markup_display",
    data: "product.buy_box_markup_display",
    render: function (data, type, row) {
      const error = row.product.buy_box_markup_error;
      const missingPieces = row.product.buy_box_markup_missing_pieces;

      if (error === "Misthread") {
        return "Misthread";
      }

      if (error === "Ineligible") {
        return '<b><font size="8"> &#8734;</font></b>';
      }

      if (error === "Not applicable") {
        return '<span style="color:Purple"><b><font size="5">N/A</font></b></span>';
      }

      if (missingPieces) {
        return `<div style='display:flex;align-items:center;gap:7px;'><img src="https://i.ibb.co/Nsdhbrz/1.png" height="30px" alt="image" /> <b style='font-size:20px;'>${missingPieces}</b></div>`;
      }

      if (data === null) return null;

      const percentage = data.match(/[0-9]+/);

      if (data.includes("Premium")) {
        return `<div style='display:flex;align-items:center;gap:7px;'><b>+${percentage[0]}%</b><img src="https://i.ibb.co/m9Dj2FY/Green-Arrow.png" style='color:green;'></img></div>`;
      }

      if (data.includes("Discount")) {
        return `<div style='display:flex;align-items:center;gap:7px;'><b>${percentage[0]}%</b><img src="https://i.ibb.co/pXbvMqQ/Red-Arrow.png" style='color:green;'></img></div>`;
      }

      if (data.includes("PAR")) {
        return "<b>PAR</div>";
      }

      return data;
    },
  },

  {
    name: "product.fba_latest_shipment_quantity_timestamp",
    data: "product.fba_latest_shipment_quantity_timestamp",
    visible: false,
  },
  {
    name: "product.fba_latest_shipment_quantity",
    data: "product.fba_latest_shipment_quantity",
    label:
      '<Tooltip title = "Quantity last shipped to Amazon FBA facility. Displays timestamp on hover" position = "bottom" trigger = "click"><p>FBA Last Shipment</p></Tooltip>',
    title:
      '<Tooltip title = "Quantity last shipped to Amazon FBA facility. Displays timestamp on hover" position = "bottom" trigger = "click"><p>FBA Last Shipment</p></Tooltip>',
    render: function (data, type, row) {
      if (!data) return null;

      const updatedTimestamp = renderDate(
        row.product.fba_latest_shipment_quantity_timestamp
      );

      return `<span title='Updated: ${updatedTimestamp}'>${data}</span>`;
    },
  },
  {
    label:
      '<Tooltip title = "WN (Warehouse notes)" position = "bottom" trigger = "click"><p>WN</p></Tooltip>',
    name: "product.wn",
    data: "product.wn",
    title:
      '<Tooltip title = "WN (Warehouse notes)" position = "bottom" trigger = "click"><p>WN</p></Tooltip>',
  },
  {
    label:
      '<Tooltip title = "WWO (Warehouse work orders)" position = "bottom" trigger = "click"><p>WWO</p></Tooltip>',
    name: "product.wwo",
    data: "product.wwo",
    title:
      '<Tooltip title = "WWO (Warehouse work orders)" position = "bottom" trigger = "click"><p>WWO</p></Tooltip>',
  },
  {
    label:
      '<Tooltip title = "Significant events in the life of a product listing" position = "bottom" trigger = "click"><p>Listory</p></Tooltip>',
    name: "product.listory",
    data: "product.listory",
    title:
      '<Tooltip title = "Significant events in the life of a product listing" position = "bottom" trigger = "click"><p>Listory</p></Tooltip>',
  },
  {
    label:
      '<Tooltip title =  "Pick Status" position =  "bottom " trigger =  "click "><p>Pick Status</p></Tooltip>',
    name: "product.pick_status",
    data: "product.pick_status",
    title:
      '<Tooltip title =  "Pick Status" position =  "bottom " trigger =  "click "><p>Pick Status</p></Tooltip>',
  },

  {
    label:
      '<Tooltip title =  "The master average cost of goods paid for the product using the AVCG-H 90 value." position =  "bottom " trigger =  "click "><p>MAVCG-H 90</p></Tooltip>',
    name: "product.master_avg_cogs_ninety_day",
    data: "product.master_avg_cogs_ninety_day",
    title:
      '<Tooltip title =  "The master average cost of goods paid for the product using the AVCG-H 90 value." position =  "bottom " trigger =  "click "><p>MAVCG-H 90</p></Tooltip>',
    render: function (data, type) {
      if (data === 9999.99) return "Misthreading error.";
      if (data === 9999.98) return "Inventory error.";
      return data;
    },
    excludeFromGeneralSearch: true,
  },
  {
    label:
      '<Tooltip title =  "The average (mean) cost paid for the product in our warehouse in the last 90 days." position =  "bottom " trigger =  "click "><p>AVCG-H 90</p></Tooltip>',
    name: "product.last_ninety_day_avg_cogs",
    data: "product.last_ninety_day_avg_cogs",
    title:
      '<Tooltip title =  "The average (mean) cost paid for the product in our warehouse in the last 90 days." position =  "bottom " trigger =  "click "><p>AVCG-H 90</p></Tooltip>',
  },
  {
    label:
      '<Tooltip title =  "The lowest cost paid for the product in our warehouse in the last year." position =  "bottom " trigger =  "click "><p>One Year Lowest COGS</p></Tooltip>',
    name: "product.one_year_lowest_cost",
    data: "product.one_year_lowest_cost",
    title:
      '<Tooltip title =  "The lowest cost paid for the product in our warehouse in the last year." position =  "bottom " trigger =  "click "><p>One Year Lowest COGS</p></Tooltip>',
    excludeFromGeneralSearch: true,
  },

  {
    label:
      '<Tooltip title =  "Next Shipment - number of units to be shipped" position =  "bottom " trigger =  "click "><p>Next Ship Qty</p></Tooltip>',
    name: "product.next_amz_ship_qty",
    data: "product.next_amz_ship_qty",
    title:
      '<Tooltip title =  "Next Shipment - number of units to be shipped" position =  "bottom " trigger =  "click "><p>Next Ship Qty</p></Tooltip>',
    excludeFromGeneralSearch: true,
  },
  {
    label:
      '<Tooltip title =  "Yom\'s new ship stock target value used in YSR holiday calc" position =  "bottom " trigger =  "click "><p>YSR NSS Rec</p></Tooltip>',
    name: "product.ysr_nss_rec",
    data: "product.ysr_nss_rec",
    type: "readonly",
    attr: {
      disabled: true,
    },
    title:
      '<Tooltip title =  "Yom\'s new ship stock target value used in YSR holiday calc" position =  "bottom " trigger =  "click "><p>YSR NSS Rec</p></Tooltip>',
    excludeFromGeneralSearch: true,
  },
  {
    label:
      '<Tooltip title =  "Yom\'s purchase recommendation" position =  "bottom " trigger =  "click "><p>YPR</p></Tooltip>',
    name: "product.yom_purchase_rec",
    data: "product.yom_purchase_rec",
    type: "readonly",
    attr: {
      disabled: true,
    },
    title:
      '<Tooltip title =  "Yom\'s purchase recommendation" position =  "bottom " trigger =  "click "><p>YPR</p></Tooltip>',
    excludeFromGeneralSearch: true,
  },
  {
    label:
      '<Tooltip title =  "Yom\'s recommended ship quantity scaled for holidays" position =  "bottom " trigger =  "click "><p>YSR Holiday</p></Tooltip>',
    name: "product.yom_ship_rec_holiday",
    data: "product.yom_ship_rec_holiday",
    type: "readonly",
    attr: {
      disabled: true,
    },
    title:
      '<Tooltip title =  "Yom\'s recommended ship quantity scaled for holidays" position =  "bottom " trigger =  "click "><p>YSR Holiday</p></Tooltip>',
    excludeFromGeneralSearch: true,
  },
  {
    label:
      '<Tooltip title =  "Yom\'s recommended ship quantity" position =  "bottom " trigger =  "click "><p>YSR</p></Tooltip>',
    name: "product.yom_ship_rec",
    data: "product.yom_ship_rec",
    attr: {
      disabled: true,
    },
    title:
      '<Tooltip title =  "Yom\'s recommended ship quantity" position =  "bottom " trigger =  "click "><p>YSR</p></Tooltip>',
    excludeFromGeneralSearch: true,
  },
  {
    label:
      '<Tooltip title =  "Amazon\'s recommended ship quantity" position =  "bottom " trigger =  "click "><p>ASR</p></Tooltip>',
    name: "product.amz_recommended_ship_quantity",
    data: "product.amz_recommended_ship_quantity",
    title:
      '<Tooltip title =  "Amazon\'s recommended ship quantity" position =  "bottom " trigger =  "click "><p>ASR</p></Tooltip>',
    excludeFromGeneralSearch: true,
  },
  {
    label:
      '<Tooltip title =  "ASQ (Amazon Ship Quantity) Number of units to be shipped to Amazon\'s warehouse network from our warehouse " position =  "bottom " trigger =  "click "><p>Amz Ship Qty</p></Tooltip>',
    name: "product.amz_ship_qty",
    data: "product.amz_ship_qty",
    title:
      '<Tooltip title =  "ASQ (Amazon Ship Quantity) Number of units to be shipped to Amazon\'s warehouse network from our warehouse " position =  "bottom " trigger =  "click "><p>Amz Ship Qty</p></Tooltip>',
    excludeFromGeneralSearch: true,
  },
  {
    label:
      '<Tooltip title =  "Difference between YOM ship recommendation and AMZ ship recommendation" position =  "bottom " trigger =  "click "><p>YSR ASR Diff</p></Tooltip>',
    name: "product.yom_ship_rec_amz_ship_rec_diff",
    data: "product.yom_ship_rec_amz_ship_rec_diff",
    attr: {
      disabled: true,
    },
    title:
      '<Tooltip title =  "Difference between YOM ship recommendation and AMZ ship recommendation" position =  "bottom " trigger =  "click "><p>YSR ASR Diff</p></Tooltip>',
    excludeFromGeneralSearch: true,
  },
  {
    label:
      '<Tooltip title =  "Projected profit from selling YOM ship recommended quantity" position =  "bottom " trigger =  "click "><p>YSR Potential Profit</p></Tooltip>',
    name: "product_profit_projection_view.yom_ship_rec_potential_profit",
    data: "product_profit_projection_view.yom_ship_rec_potential_profit",
    title:
      '<Tooltip title =  "Projected profit from selling YOM ship recommended quantity" position =  "bottom " trigger =  "click "><p>YSR Potential Profit</p></Tooltip>',
    excludeFromGeneralSearch: true,
    attr: {
      disabled: true,
    },
    render: function (data, type) {
      if (!data) return null;

      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(data);
    },
  },
  {
    label:
      '<Tooltip title =  "Total days with restock status = IS in the past 30 days. Reported as # days (%)" position =  "bottom " trigger =  "click "><p>30 Day IS Rate</p></Tooltip>',
    name: "product.30_day_restock_in_stock_count",
    data: "product.30_day_restock_in_stock_count",
    title:
      '<Tooltip title =  "Total days with restock status = IS in the past 30 days. Reported as # days (%)" position =  "bottom " trigger =  "click "><p>30 Day IS Rate</p></Tooltip>',
    excludeFromGeneralSearch: true,
    attr: {
      disabled: true,
    },
    render: function (data, type) {
      if (data === undefined || data === null) return null;

      const percentage = new BigNumber(data).dividedBy(30).multipliedBy(100);
      return `${data} (${percentage.toFixed(0)}%)`;
    },
  },
  {
    label:
      '<Tooltip title =  "Experimental column from Kelsey\'s Analytics DB" position =  "bottom " trigger =  "click "><p>Analytics DB 1</p></Tooltip>',
    name: "product.analytics_db_column_1",
    data: "product.analytics_db_column_1",
    title:
      '<Tooltip title =  "Experimental column from Kelsey\'s Analytics DB" position =  "bottom " trigger =  "click "><p>Analytics DB 1</p></Tooltip>',
    excludeFromGeneralSearch: true,
  },
  {
    label:
      '<Tooltip title =  "Experimental column from Kelsey\'s Analytics DB" position =  "bottom " trigger =  "click "><p>Analytics DB 2</p></Tooltip>',
    name: "product.analytics_db_column_2",
    data: "product.analytics_db_column_2",
    title:
      '<Tooltip title =  "Experimental column from Kelsey\'s Analytics DB" position =  "bottom " trigger =  "click "><p>Analytics DB 2</p></Tooltip>',
    excludeFromGeneralSearch: true,
  },
  {
    label:
      '<Tooltip title =  "Experimental column from Kelsey\'s Analytics DB" position =  "bottom " trigger =  "click "><p>Analytics DB 3</p></Tooltip>',
    name: "product.analytics_db_column_3",
    data: "product.analytics_db_column_3",
    title:
      '<Tooltip title =  "Experimental column from Kelsey\'s Analytics DB" position =  "bottom " trigger =  "click "><p>Analytics DB 3</p></Tooltip>',
    excludeFromGeneralSearch: true,
  },
  {
    label:
      '<Tooltip title =  "Experimental column from Kelsey\'s Analytics DB" position =  "bottom " trigger =  "click "><p>Analytics DB 4</p></Tooltip>',
    name: "product.analytics_db_column_4",
    data: "product.analytics_db_column_4",
    title:
      '<Tooltip title =  "Experimental column from Kelsey\'s Analytics DB" position =  "bottom " trigger =  "click "><p>Analytics DB 4</p></Tooltip>',
    excludeFromGeneralSearch: true,
  },
  {
    label:
      '<Tooltip title =  "ASD (Amazon Ship Date) Date units must be shipped to Amazon\'s warehouse network from our warehouse" position =  "bottom " trigger =  "click "><p>Amazon Queue</p></Tooltip>',
    name: "product.amz_queue",
    data: "product.amz_queue",
    title:
      '<Tooltip title =  "ASD (Amazon Ship Date) Date units must be shipped to Amazon\'s warehouse network from our warehouse" position =  "bottom " trigger =  "click "><p>Amazon Queue</p></Tooltip>',
    render: function (data, type) {
      if (data) {
        return type === "display" && data.length > 30
          ? data.substr(0, 30) + "…"
          : data;
      } else {
        return "";
      }
    },
  },

  {
    label:
      '<Tooltip title =  "Marking column (General Use) " position =  "bottom " trigger =  "click "><p>Orders</p></Tooltip>',
    name: "product.orders",
    data: "product.orders",
    title:
      '<Tooltip title =  "Marking column (General Use) " position =  "bottom " trigger =  "click "><p>Orders</p></Tooltip>',
    render: function (data, type) {
      if (data) {
        return type === "display" && data.length > 30
          ? data.substr(0, 30) + "…"
          : data;
      } else {
        return "";
      }
    },
  },
  {
    label:
      '<Tooltip title =  "BCP (Barcode Printed) Whether or not the barcode has been printed for this product (ex. Yes, No)" position =  "bottom " trigger =  "click "><p>BCP</p></Tooltip>',
    name: "product.bcp",
    data: "product.bcp",
    title:
      '<Tooltip title =  "BCP (Barcode Printed) Whether or not the barcode has been printed for this product (ex. Yes, No)" position =  "bottom " trigger =  "click "><p>BCP</p></Tooltip>',
    excludeFromGeneralSearch: true,
  },
  {
    label:
      '<Tooltip title =  "AN (Analyst Notes) Notes for the analyst to use" position =  "bottom " trigger =  "click "><p>AN</p></Tooltip>',
    name: "product.an",
    data: "product.an",
    title:
      '<Tooltip title =  "AN (Analyst Notes) Notes for the analyst to use" position =  "bottom " trigger =  "click "><p>AN</p></Tooltip>',
  },

  {
    label: "Shelf",
    name: "product.shelf",
    data: "product.shelf",
    title:
      '<Tooltip title =  "Where the item can be found in our warehouse " position =  "bottom " trigger =  "click "><p>Shelf</p></Tooltip>',
  },

  {
    label:
      '<Tooltip title =  "alpha1 " position =  "bottom " trigger =  "click "><p>alpha1</p></Tooltip>',
    name: "product.alpha1",
    data: "product.alpha1",
    title:
      '<Tooltip title =  "See Operations Document for current column use" position =  "bottom " trigger =  "click "><p>Alpha 1</p></Tooltip>',
  },
  {
    label:
      '<Tooltip title =  "alpha2 " position =  "bottom " trigger =  "click "><p>alpha2</p></Tooltip>',
    name: "product.alpha2",
    data: "product.alpha2",
    title:
      '<Tooltip title =  "See Operations Document for current column use" position =  "bottom " trigger =  "click "><p>Alpha 2</p></Tooltip>',
  },
  {
    label:
      '<Tooltip title =  "alpha3 " position =  "bottom " trigger =  "click "><p>alpha3</p></Tooltip>',
    name: "product.alpha3",
    data: "product.alpha3",
    title:
      '<Tooltip title =  "See Operations Document for current column use" position =  "bottom " trigger =  "click "><p>Alpha 3</p></Tooltip>',
  },
  {
    label:
      '<Tooltip title =  "num1 " position =  "bottom " trigger =  "click "><p>num1</p></Tooltip>',
    name: "product.num1",
    data: "product.num1",
    title:
      '<Tooltip title =  "See Operations Document for current column use" position =  "bottom " trigger =  "click "><p>Num 1</p></Tooltip>',
    excludeFromGeneralSearch: true,
  },
  {
    label:
      '<Tooltip title =  "num2 " position =  "bottom " trigger =  "click "><p>num2</p></Tooltip>',
    name: "product.num2",
    data: "product.num2",
    title:
      '<Tooltip title =  "See Operations Document for current column use" position =  "bottom " trigger =  "click "><p>Num 2</p></Tooltip>',
    excludeFromGeneralSearch: true,
  },
  {
    label:
      '<Tooltip title =  "num3 " position =  "bottom " trigger =  "click "><p>num3</p></Tooltip>',
    name: "product.num3",
    data: "product.num3",
    title:
      '<Tooltip title =  "See Operations Document for current column use" position =  "bottom " trigger =  "click "><p>Num 3</p></Tooltip>',
    excludeFromGeneralSearch: true,
  },
  {
    label: "Product List Type",
    name: "product.prod_list_type",
    data: "product.prod_list_type",
    title:
      '<Tooltip title =  "Product List Type" position =  "bottom " trigger =  "click "><p>Product List Type</p></Tooltip>',
  },
  {
    label:
      '<Tooltip title =  "Your Online Marketplace\'s current listing price" position =  "bottom " trigger =  "click "><p>Listing Price</p></Tooltip>',
    name: "product_listings.price",
    data: "product_listings.price",
    title:
      '<Tooltip title =  "Your Online Marketplace\'s current listing price" position =  "bottom " trigger =  "click "><p>Listing Price</p></Tooltip>',
    excludeFromGeneralSearch: true,
  },
  {
    label:
      '<Tooltip title =  "FNSKU (Amazon\'s Fulfillment Network SKU) " position =  "bottom " trigger =  "click "><p>FNSKU</p></Tooltip>',
    name: "product.fnsku",
    data: "product.fnsku",
    title:
      '<Tooltip title =  "FNSKU (Amazon\'s Fulfillment Network SKU) " position =  "bottom " trigger =  "click "><p>FNSKU</p></Tooltip>',
  },
  {
    label:
      '<Tooltip title =  "Purchasable quantity available on Amazon " position =  "bottom " trigger =  "click "><p>Stock</p></Tooltip>',
    name: "product.stock",
    data: "product.stock",
    title:
      '<Tooltip title =  "Purchasable quantity available on Amazon " position =  "bottom " trigger =  "click "><p>Stock</p></Tooltip>',
    excludeFromGeneralSearch: true,
  },
  {
    label:
      '<Tooltip title =  "Your Online Marketplace\'s listing status (F - FBA, M - Merchant, .A - Active, .Inactive, .Incomplete) " position =  "bottom " trigger =  "click "><p>Status</p></Tooltip>',
    name: "product.status",
    data: "product.status",
    title:
      '<Tooltip title =  "Your Online Marketplace\'s listing status (F - FBA, M - Merchant, .A - Active, .Inactive, .Incomplete) " position =  "bottom " trigger =  "click "><p>Status</p></Tooltip>',
  },
  {
    label:
      '<Tooltip title =  "How many days since Your Online Marketplace has sold this product " position =  "bottom " trigger =  "click "><p>Time Since Last Order</p></Tooltip>',
    name: "product_listings.last_non_cancelled_order_timestamp",
    attr: {
      disabled: true,
    },
    data: "product_listings.last_non_cancelled_order_timestamp",
    title:
      '<Tooltip title =  "How many days since Your Online Marketplace has sold this product " position =  "bottom " trigger =  "click "><p>Time Since Last Order</p></Tooltip>',
    render: function (data, type, row) {
      if (!data) return null;

      const today = new Date();
      const lastestOrderDate = new Date(data * 1000);
      return hourlyOrDailyDateDifference(today, lastestOrderDate);
    },
  },
  {
    label:
      '<Tooltip title =  "Restock Shipped Stock = Available + FC Transfer + FC Processing + Working + Shipped + Receiving " position =  "bottom " trigger =  "click "><p>New Shipped Stock</p></Tooltip>',
    name: "product.ship_stock",
    data: "product.ship_stock",
    title:
      '<Tooltip title =  "Restock Shipped Stock = Available + FC Transfer + FC Processing + Working + Shipped + Receiving " position =  "bottom " trigger =  "click "><p>New Shipped Stock</p></Tooltip>',
    excludeFromGeneralSearch: true,
  },
  {
    label:
      '<Tooltip title =  "Number of unsold units at Amazon\'s warehouse that have been there for more than 270 days" position =  "bottom " trigger =  "click "><p>Inv Age 271- 365</p></Tooltip>',
    name: "product.inv_age_271_to_365",
    data: "product.inv_age_271_to_365",
    title:
      '<Tooltip title =  "Number of unsold units at Amazon\'s warehouse that have been there for more than 270 days" position =  "bottom " trigger =  "click "><p>Inv Age 271- 365</p></Tooltip>',
  },
  {
    label:
      '<Tooltip title =  "Amazon\'s recommendation for fixing a poor preforming listing " position =  "bottom " trigger =  "click "><p>Alert</p></Tooltip>',
    name: "product.alert",
    data: "product.alert",
    title:
      '<Tooltip title =  "Amazon\'s recommendation for fixing a poor preforming listing " position =  "bottom " trigger =  "click "><p>Alert</p></Tooltip>',
  },
  {
    label:
      '<Tooltip title =  "Units sold and shipped over the past 90 days divided by the average number of units available at fulfillment centers during that time period. A higher number means we sell through inventory fast, and vice versa. " position =  "bottom " trigger =  "click "><p>FBA Sell Thru</p></Tooltip>',
    name: "product.fba_sell_through",
    data: "product.fba_sell_through",
    title:
      '<Tooltip title =  "Units sold and shipped over the past 90 days divided by the average number of units available at fulfillment centers during that time period. A higher number means we sell through inventory fast, and vice versa. " position =  "bottom " trigger =  "click "><p>FBA Sell Thru</p></Tooltip>',
    excludeFromGeneralSearch: true,
  },
  {
    label:
      '<Tooltip title = "COGS (Cost of Goods Sold) Price Your Online Marketplace paid for our goods, inputed by a analyst" position = "bottom" trigger = "click"><p>COGS</p></Tooltip>',
    name: "product.cogs",
    data: "product.cogs",
    title:
      '<Tooltip title = "COGS (Cost of Goods Sold) Price Your Online Marketplace paid for our goods, inputed by a analyst" position = "bottom" trigger = "click"><p>COGS</p></Tooltip>',
    render: (data) => {
      if (data !== null) {
        return `$${(data / 100).toFixed(2)}`;
      } else {
        return data;
      }
    },
    excludeFromGeneralSearch: true,
  },
  {
    label:
      '<Tooltip title = "Shows the timestamp of the last time COGS was updated for a row." position = "bottom" trigger = "click"><p>COGS Timestamp</p></Tooltip>',
    name: "product.cogs_update_timestamp",
    data: "product.cogs_update_timestamp",
    title:
      '<Tooltip title = "Shows the timestamp of the last time COGS was updated for a row." position = "bottom" trigger = "click"><p>COGS Timestamp</p></Tooltip>',
    render: renderDate,
  },
  {
    label:
      '<Tooltip title = "Representation if the product was repriced or prospected using our Amazon pricing calculator" position = "bottom" trigger = "click"><p>Type</p></Tooltip>',
    name: "product.type",
    data: "product.type",
    title:
      '<Tooltip title = "Representation if the product was repriced or prospected using our Amazon pricing calculator" position = "bottom" trigger = "click"><p>Type</p></Tooltip>',
    excludeFromGeneralSearch: true,
  },
  {
    label:
      '<Tooltip title = "Profit margin percentage" position = "bottom" trigger = "click"><p>Margin</p></Tooltip>',
    name: "product.margin",
    data: "product.margin",
    title:
      '<Tooltip title = "Profit margin percentage" position = "bottom" trigger = "click"><p>Margin</p></Tooltip>',
    render: (data) => {
      if (data !== null) {
        return `${data.toFixed(1)}%`;
      } else {
        return data;
      }
    },
  },
  {
    label:
      '<Tooltip title = "Profit that we get if we were to sell the product (Selling Price - COGS - Amazon fees - Your Online Marketplace Warehouse & Handling fees)" position = "bottom" trigger = "click"><p>UPC Profit</p></Tooltip>',
    name: "product.profit",
    data: "product.profit",
    title:
      '<Tooltip title = "Profit that we get if we were to sell the product (Selling Price - COGS - Amazon fees - Your Online Marketplace Warehouse & Handling fees)" position = "bottom" trigger = "click"><p>UPC Profit</p></Tooltip>',
    render: (data) => {
      if (data !== null) {
        return `$${(data / 100).toFixed(2)}`;
      } else {
        return data;
      }
    },
    excludeFromGeneralSearch: true,
  },
  {
    label:
      '<Tooltip title = "Selling Price - Amazon fees directly attributable to the sale and fulfillment of an FBA order (inc. referral, fulfillment and closing fees)" position = "bottom" trigger = "click"><p>Proceeds</p></Tooltip>',
    name: "product.proceeds",
    data: "product.proceeds",
    title:
      '<Tooltip title = "Selling Price - Amazon fees directly attributable to the sale and fulfillment of an FBA order (inc. referral, fulfillment and closing fees)" position = "bottom" trigger = "click"><p>Proceeds</p></Tooltip>',
    render: (data) => {
      if (data !== null) {
        return `$${(data / 100).toFixed(2)}`;
      } else {
        return data;
      }
    },
    excludeFromGeneralSearch: true,
  },
  {
    label:
      '<Tooltip title = "ROI (Return on Investment) The gain or loss on an investment relative to the amount of money invested (COGS = Warehousing Fee)" position = "bottom" trigger = "click"><p>ROI</p></Tooltip>',
    name: "product.roi",
    data: "product.roi",
    title:
      '<Tooltip title = "ROI (Return on Investment) The gain or loss on an investment relative to the amount of money invested (COGS = Warehousing Fee)" position = "bottom" trigger = "click"><p>ROI</p></Tooltip>',
    render: (data) => {
      if (data !== null) {
        return `${data.toFixed(1)}%`;
      } else {
        return data;
      }
    },
    excludeFromGeneralSearch: true,
  },
  {
    label:
      '<Tooltip title = "Selling Price required to achieve a 0% margin" position = "bottom" trigger = "click"><p>List 0</p></Tooltip>',
    name: "product.margin_0_pricing",
    data: "product.margin_0_pricing",
    title:
      '<Tooltip title = "Selling Price required to achieve a 0% margin" position = "bottom" trigger = "click"><p>List 0</p></Tooltip>',
    render: (data) => {
      if (data !== null) {
        return `$${(data / 100).toFixed(2)}`;
      } else {
        return data;
      }
    },
    excludeFromGeneralSearch: true,
  },
  {
    label:
      '<Tooltip title = "Selling Price required to achieve a 15% margin" position = "bottom" trigger = "click"><p>List 15</p></Tooltip>',
    name: "product.margin_15_pricing",
    data: "product.margin_15_pricing",
    title:
      '<Tooltip title = "Selling Price required to achieve a 15% margin" position = "bottom" trigger = "click"><p>List 15</p></Tooltip>',
    render: (data) => {
      if (data !== null) {
        return `$${(data / 100).toFixed(2)}`;
      } else {
        return data;
      }
    },
    excludeFromGeneralSearch: true,
  },
  {
    label:
      '<Tooltip title = "Selling Price required to achieve a 30% margin" position = "bottom" trigger = "click"><p>List 30</p></Tooltip>',
    name: "product.margin_30_pricing",
    data: "product.margin_30_pricing",
    title:
      '<Tooltip title = "Selling Price required to achieve a 30% margin" position = "bottom" trigger = "click"><p>List 30</p></Tooltip>',
    render: (data) => {
      if (data !== null) {
        return `$${(data / 100).toFixed(2)}`;
      } else {
        return data;
      }
    },
    excludeFromGeneralSearch: true,
  },
  {
    label:
      '<Tooltip title = "When a product was last prospected or priced" position = "bottom" trigger = "click"><p>Calculator TimeStamp</p></Tooltip>',
    name: "product.latest_timestamp",
    data: "product.latest_timestamp",
    title:
      '<Tooltip title = "When a product was last prospected or priced" position = "bottom" trigger = "click"><p>Calculator TimeStamp</p></Tooltip>',
    render: renderDate,
    excludeFromGeneralSearch: true,
  },
];

/**
 * Returns array of all columns for a given column set.
 * Will use the masterProductColumnSet if no column names are given.
 * @param {array} columnNames Optional
 * @returns {array}
 */
export function getColumnSet(columnNames) {
  if (!columnNames) return masterProductColumnSet;

  const columns = [];

  columnNames.forEach((col) => {
    const column = masterProductColumnSet.find(
      (colDefinition) => colDefinition.data === col
    );
    if (column) columns.push(column);
  });

  const columnsToHide = masterProductColumnSet
    .map((col) => col.name)
    .filter((name) => !columnNames.includes(name));

  // adding rest of columns from master set as hidden - edits will be broken otherwise
  const nonViewableColumns = masterProductColumnSet
    .filter((col) => columnsToHide.includes(col.data))
    .map((col) => ({ ...col, visible: false, type: "hidden" }));

  return [...columns, ...nonViewableColumns];
}

/**
 * Returns array of only *viewable* columns for a given column set.
 * Will use the masterProductColumnSet if no column names are given.
 * @param {array} columnNames Optional
 * @returns {array}
 */
export function getViewableProductColumns(columnNames) {
  let columnSet = [];
  if (!columnNames) {
    columnSet = masterProductColumnSet.map((c) => c);
  } else {
    columnSet = getColumnSet(columnNames);
  }

  // Adding column dependencies
  columnSet
    .map((c) => c)
    .forEach((col) => {
      if (col.columnDependencies) {
        col.columnDependencies.forEach((name) => {
          if (!columnSet.find((c) => c.data === name))
            columnSet.push({
              data: name,

              visible: false,
            });
        });
      }
    });

  return columnSet;
}
