import React from "react";
import Barcode from "react-barcode";
import { Button } from "semantic-ui-react";
import _ from "lodash";
import ReactToPrint from "react-to-print";

import "./BarcodeGenerator.css";

var uniqid = require("uniqid");

// generates a barcode
class BarcodeGenerator extends React.Component {
  render() {
    // amazon barcode uses fnsku
    if (this.props.barCodeType === "amazon") {
      const barcodes = _.map(this.props.rowData, (row, index) => {
        let { amazon_title, fnsku } = row.product;
        const { cond, wn } = row.product;
        let title = amazon_title;
        let title2 = "";

        if (!title) {
          title = "No Title";
        }

        if (title.length > 70) {
          title2 = title.slice(35, 70) + "...";
          title = title.slice(0, 35);
        } else if (title.length > 35) {
          title2 = title.slice(35);
          title = title.slice(0, 35);
        }

        return (
          <React.Fragment key={uniqid()}>
            <Barcode
              value={fnsku ? fnsku : "No FnSKU"}
              width={this.props.width}
              height={this.props.height}
              format={this.props.format}
              displayValue={this.props.displayValue}
              fontOptions={this.props.fontOptions}
              font={this.props.font}
              textAlign={this.props.center}
              textPosition={this.props.textPosition}
              textMargin={this.props.textMargin}
              fontSize={this.props.fontSize}
              background={this.props.background}
              lineColor={this.props.lineColor}
              margin={this.props.margin}
              marginTop={this.props.marginTop}
              marginBottom={this.props.marginBottom}
              marginLeft={this.props.marginLeft}
              marginRight={this.props.marginRight}
            />
            <div>{title}</div>
            <div>{title2}</div>
            <div>
              {wn ? wn : "No WN"} | {cond ? cond : "New"}
            </div>
            {index !== this.props.rowData.length - 1 && (
              <div className="page-break"></div>
            )}
          </React.Fragment>
        );
      });

      return (
        <React.Fragment>
          <ReactToPrint
            trigger={() => {
              return <Button>Print barcodes</Button>;
            }}
            content={() => this.barcodeRef}
          />
          <div ref={(el) => (this.barcodeRef = el)}>{barcodes}</div>
        </React.Fragment>
      );
      // uses upc as barcode
    } else if (this.props.barCodeType === "upc") {
      const barcodes = _.map(this.props.rowData, (row, index) => {
        const { upc } = row.supply;

        return (
          <React.Fragment>
            <Barcode
              value={upc ? upc : "No UPC"}
              width={this.props.width}
              height={this.props.height}
              format={this.props.format}
              displayValue={this.props.displayValue}
              fontOptions={this.props.fontOptions}
              font={this.props.font}
              textAlign={this.props.center}
              textPosition={this.props.textPosition}
              textMargin={this.props.textMargin}
              fontSize={this.props.fontSize}
              background={this.props.background}
              lineColor={this.props.lineColor}
              margin={this.props.margin}
              marginTop={this.props.marginTop}
              marginBottom={this.props.marginBottom}
              marginLeft={this.props.marginLeft}
              marginRight={this.props.marginRight}
            />
            {index !== this.props.rowData.length - 1 && (
              <div className="page-break"></div>
            )}
          </React.Fragment>
        );
      });

      return (
        <React.Fragment>
          <ReactToPrint
            trigger={() => {
              return <Button>Print barcodes</Button>;
            }}
            content={() => this.barcodeRef}
          />
          <div ref={(el) => (this.barcodeRef = el)}>{barcodes}</div>
        </React.Fragment>
      );
    }

    return (
      <div className="ui active transition visible dimmer">
        <div className="content">
          <div className="ui loader"></div>
        </div>
      </div>
    );
  }
}

export default BarcodeGenerator;
