import axios from "./dvdlocator";

export async function getColumnSetsForUserId(userId) {
  try {
    const response = await axios.get("/nosql/column-sets", {
      params: { username: userId },
    });
    const columnSets = Object.entries(response.data.Items[0].columns).map(
      (entry) => ({ columnSetName: entry[0], columns: entry[1] })
    );

    return columnSets;
  } catch (err) {
    console.log(err);
  }
}
