import React, { useRef, useEffect } from "react";
import { Icon, Menu } from "semantic-ui-react";

import "./ContextMenu.css";

const ProductContextMenu = ({
  open,
  event,
  closeContextMenu,
  selectedRows,
  rowsPreselected,
  openContextModal,
}) => {
  const contextMenuRef = useRef(null);

  useEffect(() => {
    if (!open) return;

    const contextMenu = contextMenuRef.current;
    const y = event.pageY - window.scrollY;
    const x = event.pageX;

    // Style the context menu to appear at the clicked coordinates
    contextMenu.style.top = `${y}px`;
    contextMenu.style.left = `${x}px`;
    contextMenu.style.display = `block`;

    function closeMenu(e) {
      const isClickInsideContextMenu = contextMenu.contains(e.target);

      if (!isClickInsideContextMenu) closeContextMenu();
    }

    window.addEventListener("mousedown", closeMenu);

    return () => {
      window.removeEventListener("mousedown", closeMenu);
    };
  }, [open, event, closeContextMenu]);

  if (!open) return null;

  return (
    <div
      style={{
        position: "fixed",
        zIndex: 997,
        top: 0,
        left: 0,
      }}
    >
      <div
        style={{
          position: "relative",
          zIndex: 998,
        }}
      >
        <div
          ref={contextMenuRef}
          className="context-menu"
          id="pt-context-menu"
          style={{
            position: "absolute",
            width: "250px",
            zIndex: 999,
            display: "none",
          }}
        >
          <Menu vertical>
            {rowsPreselected && (
              <Menu.Item header>
                {`(${selectedRows.length})`} selected..
              </Menu.Item>
            )}
            <Menu.Item
              onMouseEnter={(e) => (e.target.style.backgroundColor = "#dcdcdc")} // Change to darker color on hover
              onMouseLeave={(e) => (e.target.style.backgroundColor = "#f8f8f8")} // Reset to original color on hover out
              onClick={() => openContextModal("family")}
              className="no-pointer"
            >
              <Icon name="edit outline" />
              Edit Families
            </Menu.Item>
          </Menu>
        </div>
      </div>
    </div>
  );
};

export default ProductContextMenu;
