import React from "react";
import AppendForm from "./AppendForm";
import AppendMessage from "./AppendMessage";

import CreateWorkOrder from "./CreateWorkOrder";
import ShelvingList from "./ShelvingList";
import SumColumnForm from "./SumColumn";
import SupplierDataCSVModal from "./SupplierCsvUpload/SupplierCsvUpload";
import ClearSupplierDataModal from "./ClearSupplierData/ClearSupplierData";

/**
 * Handles switching between modals in Pricetool.
 */
const ModalSwitch = ({
  closeModal,
  selectedRows,
  modalName,
  appendStatus,
  appendErrors,
  columnNames,
  submitAppendForm,
  mode,
}) => {
  switch (modalName) {
    case "DamageWorkOrder":
      return <CreateWorkOrder rows={selectedRows} closeModal={closeModal} />;
    case "ShelvingList":
      return <ShelvingList closeModal={closeModal} />;
    case "SumColumn":
      return (
        <SumColumnForm
          rows={selectedRows}
          closeModal={closeModal}
          mode={mode}
        />
      );
    case "AppendMessage":
      return (
        <AppendMessage
          rows={selectedRows}
          closeModal={closeModal}
          status={appendStatus}
          errors={appendErrors}
        />
      );
    case "AppendForm":
      return (
        <AppendForm
          columnNames={columnNames}
          closeModal={closeModal}
          submitForm={submitAppendForm}
        />
      );
    case "UploadSupplierData":
      return <SupplierDataCSVModal closeModal={closeModal} />;
    case "ClearSupplierData":
      return <ClearSupplierDataModal closeModal={closeModal} />;
    default:
      return null;
  }
};

export default ModalSwitch;
