/**
 * Converts a date into YOM-friendly formatting.
 * @param {Date} date The date to format.
 * @returns {string} formatted date string.
 */
const formatDate = (date) => {
  const dateFormatter = Intl.DateTimeFormat("en-US", {
    year: "numeric",
    month: "short",
    day: "2-digit",
    hourCycle: "h12",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  });
  const formattedDate = dateFormatter.format(date);

  return formattedDate.replace(/,/g, "");
};

/**
 * Render function that formats dates for datatable columns.
 * @param {number|string} input A UNIX timestamp in milliseconds or seconds, or a date/datetime string
 * @returns {string} formatted date string
 */
export const renderDate = (input) => {
  if (!input) return null;

  let date;

  // If input is a number or numeric string
  if (typeof input === "number" || /^\d+$/.test(input)) {
    const timestampStr = input.toString();

    // Check timestamp length to determine if it's in ms or seconds
    if (timestampStr.length === 13) {
      // Milliseconds timestamp
      date = new Date(Number(input));
    } else if (timestampStr.length === 10) {
      // Seconds timestamp
      date = new Date(Number(input) * 1000);
    } else {
      // Invalid timestamp length
      return null;
    }
  }
  // If input is a string
  else if (typeof input === "string") {
    // Try parsing the string directly
    date = new Date(input);
  } else {
    // If it's neither a number nor a string, return null
    return null;
  }

  // Validate that the date is valid
  return isNaN(date.getTime()) ? null : formatDate(date);
};
