import React from "react";
import { Form, Dropdown } from "semantic-ui-react";
import { Field, reduxForm } from "redux-form";

class ColumnSetForm extends React.Component {
  SetsDropdownFormField = (props) => (
    <Form.Field>
      <Dropdown
        selection
        {...props.input}
        value={props.input.value}
        onChange={(param, data) => props.input.onChange(data.value)}
        placeholder={props.label}
        options={this.props.columnSets.map((colSet) => {
          return {
            key: colSet.columnSetName,
            text: colSet.columnSetName,
            value: colSet.columnSetName,
          };
        })}
      />
    </Form.Field>
  );

  onColSetSubmit = (formValues) => {
    this.props.handleReorder(formValues.colSet);
  };

  render() {
    return (
      <Form>
        <Form.Group>
          <div className="ui label">Select Column Set</div>
          <Field
            name="colSet"
            component={this.SetsDropdownFormField}
            key="colSet"
          />
          <button
            onClick={this.props.handleSubmit(this.onColSetSubmit)}
            className="button ui blue inverted"
          >
            Load
          </button>
        </Form.Group>
      </Form>
    );
  }
}

export default reduxForm({
  form: "loadColSetForm",
})(ColumnSetForm);
