import React, { useState } from "react";
import {
  Table,
  Header,
  Icon,
  Segment,
  Input,
  Message,
} from "semantic-ui-react";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import axios from "../../../apis/dvdlocator";
import "./index.css";

// Validation schema for the title input
const TitleSchema = Yup.object().shape({
  title: Yup.string()
    .matches(/^[^<>]*$/, "Title cannot contain '<' or '>' characters")
    .required("Title is required"),
});

function HierarchicalSupplyUnitsList({
  supplyUnits,
  openDeleteInventoryModal,
  setSupplyUnits,
  interactable = true,
}) {
  const [editingTitleId, setEditingTitleId] = useState(null);
  const [editError, setEditError] = useState({}); // To store error messages for each inventory ID

  // Group supply units by part number, then by inventory ID
  const groupedUnits = supplyUnits.reduce((acc, unit) => {
    if (!acc[unit.part_number]) {
      acc[unit.part_number] = {};
    }
    if (!acc[unit.part_number][unit.inventory_id]) {
      acc[unit.part_number][unit.inventory_id] = [];
    }
    acc[unit.part_number][unit.inventory_id].push(unit);
    return acc;
  }, {});

  const handleEditClick = (inventoryId) => {
    setEditingTitleId(inventoryId);
    setEditError({}); // Reset error message when starting a new edit
  };

  // Original handleEditSubmit with inventory ID and values as separate arguments
  const handleEditSubmit = async (inventoryId, title) => {
    try {
      await axios.put(`/inventory/${inventoryId}`, { title });

      setSupplyUnits((prev) =>
        prev.map((unit) => {
          if (unit.inventory_id === inventoryId) {
            return { ...unit, inventory_title: title };
          }
          return unit;
        })
      );
      setEditingTitleId(null);
    } catch (err) {
      setEditError({
        [inventoryId]:
          err.response?.data?.message || "An error occurred while saving.",
      });
    }
  };

  return (
    <Segment>
      <Table basic="very">
        <Table.Header>
          <Table.Row className="large-row">
            <Table.HeaderCell>Part #</Table.HeaderCell>
            <Table.HeaderCell>Inventory ID</Table.HeaderCell>
            <Table.HeaderCell>Title</Table.HeaderCell>
            <Table.HeaderCell>UPC</Table.HeaderCell>
            <Table.HeaderCell />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {Object.entries(groupedUnits).map(([partNumber, inventories]) => (
            <React.Fragment key={partNumber}>
              <Table.Row className="large-row">
                <Table.Cell colSpan="5">
                  <Header as="h4">{partNumber}</Header>
                </Table.Cell>
              </Table.Row>
              {Object.entries(inventories).map(([inventoryId, units]) => (
                <React.Fragment key={`${partNumber}-${inventoryId}`}>
                  <Table.Row className="large-row">
                    <Table.Cell></Table.Cell>
                    <Table.Cell>{inventoryId}</Table.Cell>
                    <Table.Cell>
                      {editingTitleId === inventoryId ? (
                        <Formik
                          initialValues={{
                            title: units[0].inventory_title || "",
                          }}
                          validationSchema={TitleSchema}
                          onSubmit={async (values) =>
                            await handleEditSubmit(
                              Number(inventoryId),
                              values.title
                            )
                          }
                        >
                          {({
                            errors,
                            touched,
                            handleSubmit,
                            isSubmitting,
                          }) => (
                            <Form onSubmit={handleSubmit}>
                              <Field name="title">
                                {({ field }) => (
                                  <Input
                                    {...field}
                                    fluid
                                    placeholder="Enter new title"
                                    error={
                                      (touched.title && errors.title) ||
                                      editError[inventoryId]
                                        ? {
                                            content:
                                              errors.title ||
                                              editError[inventoryId],
                                            pointing: "below",
                                          }
                                        : null
                                    }
                                    disabled={isSubmitting}
                                  />
                                )}
                              </Field>
                              {editError[inventoryId] && (
                                <Message
                                  negative
                                  content={editError[inventoryId]}
                                  style={{ marginTop: "5px" }}
                                />
                              )}
                            </Form>
                          )}
                        </Formik>
                      ) : (
                        units[0].inventory_title || "No title"
                      )}
                    </Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell>
                      {interactable && (
                        <>
                          <Icon
                            name="trash"
                            title="Remove inventory from product"
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              openDeleteInventoryModal(
                                Number(inventoryId),
                                units
                              )
                            }
                          />
                          <Icon
                            style={{ cursor: "pointer" }}
                            title="Edit inventory title"
                            name="edit"
                            onClick={() => handleEditClick(inventoryId)}
                          />
                        </>
                      )}
                    </Table.Cell>
                  </Table.Row>
                  {units.map((unit) => (
                    <Table.Row key={unit.upc} className="large-row">
                      <Table.Cell></Table.Cell>
                      <Table.Cell></Table.Cell>
                      <Table.Cell>
                        <span
                          style={{
                            marginLeft: "2em",
                            color: "rgba(0,0,0,0.6)",
                          }}
                        >
                          {unit.supply_title}
                        </span>
                      </Table.Cell>
                      <Table.Cell>
                        <span style={{ color: "rgba(0,0,0,0.6)" }}>
                          {unit.upc}
                        </span>
                      </Table.Cell>
                      <Table.Cell />
                    </Table.Row>
                  ))}
                </React.Fragment>
              ))}
            </React.Fragment>
          ))}
        </Table.Body>
      </Table>
    </Segment>
  );
}

export { HierarchicalSupplyUnitsList };
