import React, { useEffect, useState } from "react";
import { AddSupplyUnitWizard } from "./AddSupplyUnitWizard/AddSupplyUnitWizard";
import {
  Table,
  Grid,
  Header,
  Button,
  Divider,
  Segment,
  Modal,
  Icon,
  Loader,
  Dimmer,
} from "semantic-ui-react";
import { HierarchicalSupplyUnitsList } from "./HierchichalSupplyUnitDisplay";
import axios from "../../../apis/dvdlocator";

export function AddSupplyUnitPage({
  product,
  nextPage,
  prevPage,
  setSupplyUnits,
  supplyUnits,
  duplicatingProduct,
  duplicateInitialLoadComplete,
  setDuplicateInitialLoadComplete,
  preselectedSupplyUnits,
}) {
  const [addingSupplyUnit, setAddingSupplyUnit] = useState(false);
  const [loadingSupplyUnits, setLoadingSupplyUnits] = useState(false);
  const [errorLoading, setErrorLoading] = useState("");
  const [showDeleteInventoryModal, setShowDeleteInventoryModal] =
    useState(false);
  const [inventoryToDelete, setInventoryToDelete] = useState({
    inventoryId: null,
    inventoryTitle: "",
    units: [],
  });

  useEffect(() => {
    // Prevents loading on mount
    if (duplicateInitialLoadComplete) {
      return;
    }

    let isMounted = true;

    async function loadDuplicateSupplyUnits(productId) {
      setLoadingSupplyUnits(true);
      setErrorLoading("");
      try {
        const res = await axios.get(`/supply?productId=${productId}`);
        if (isMounted) {
          setSupplyUnits(res.data);
        }
      } catch (err) {
        if (isMounted) {
          setErrorLoading(
            err.response?.data?.message ||
              "An error occurred while loading the supply units for this product. Please close form and try again."
          );
        }
      } finally {
        if (isMounted) {
          setLoadingSupplyUnits(false);
        }
      }
    }

    if (duplicatingProduct && product.duplicateProductId) {
      loadDuplicateSupplyUnits(product.duplicateProductId);
      setDuplicateInitialLoadComplete(true);
    }

    // Cleanup function
    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [duplicatingProduct, product, setSupplyUnits]);

  useEffect(() => {
    if (preselectedSupplyUnits) {
      setAddingSupplyUnit(true);
    }
  }, [preselectedSupplyUnits]);

  const openDeleteInventoryModal = (inventoryId, associatedSupplyUnits) => {
    setInventoryToDelete({
      inventoryId,
      inventoryTitle: associatedSupplyUnits[0].inventory_title,
      units: associatedSupplyUnits,
    });
    setShowDeleteInventoryModal(true);
  };

  return (
    <>
      <Segment basic>
        <p style={{ color: "gray" }}>
          Add all supply units that can fulfill this product. Supply units with
          the same inventory ID will be considered equivalent inventory units
          upon intake.
        </p>
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>ASIN</Table.HeaderCell>
              <Table.HeaderCell>Title</Table.HeaderCell>
              <Table.HeaderCell>Part Number</Table.HeaderCell>
              <Table.HeaderCell>Families</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.Cell>{product.asin}</Table.Cell>
              <Table.Cell>{product.title}</Table.Cell>
              <Table.Cell>{product.partNumber}</Table.Cell>
              <Table.Cell>
                {product.families.map((fam) => fam.title).join(", ")}
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
        <Divider section />
        <Grid>
          <Grid.Row>
            <Grid.Column floated="left" width={8}>
              <Header as="h3">Linked Supply Units</Header>
            </Grid.Column>
            <Grid.Column floated="right" width={8} textAlign="right">
              <Button
                primary={!addingSupplyUnit}
                onClick={() => setAddingSupplyUnit(!addingSupplyUnit)}
              >
                Add Supply Unit
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        {loadingSupplyUnits && (
          <Segment style={{ minHeight: "100px" }}>
            <Dimmer active={loadingSupplyUnits}>
              <Loader>Loading Supply Units..</Loader>
            </Dimmer>
          </Segment>
        )}
        {errorLoading && (
          <Segment basic negative>
            <Header as="h4" color="red">
              Error Loading Supply Units
            </Header>
            <p>{errorLoading}</p>
          </Segment>
        )}
        {/* Display nested part number -> inventory -> supply units */}
        {supplyUnits.length > 0 && (
          <HierarchicalSupplyUnitsList
            setSupplyUnits={setSupplyUnits}
            supplyUnits={supplyUnits}
            openDeleteInventoryModal={openDeleteInventoryModal}
          />
        )}
        {/* Display supply unit wizard */}
        {addingSupplyUnit && (
          <AddSupplyUnitWizard
            supplyUnits={supplyUnits}
            setSupplyUnits={setSupplyUnits}
            setAddingSupplyUnit={setAddingSupplyUnit}
            product={product}
            preselectedSupplyUnits={preselectedSupplyUnits}
          />
        )}
      </Segment>
      <Divider />
      <Segment basic>
        <Grid>
          <Grid.Row>
            <Grid.Column width={8}>
              <Button
                onClick={prevPage}
                floated="left"
                disabled={loadingSupplyUnits}
              >
                Back
              </Button>
            </Grid.Column>
            <Grid.Column width={8}>
              <Button
                primary
                onClick={nextPage}
                floated="right"
                disabled={loadingSupplyUnits}
              >
                Review
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>

      {/* Delete inventory modal */}
      <Modal
        open={showDeleteInventoryModal}
        onClose={() => setShowDeleteInventoryModal(false)}
        size="small"
      >
        <Modal.Header>
          <Header
            as="h3"
            style={{
              display: "flex",
              alignItems: "center",
              gap: "0.5em",
              margin: 0,
            }}
          >
            <Icon name="trash" color="red" size="small" />
            Remove Inventory Record
          </Header>
        </Modal.Header>

        <Modal.Content>
          <p>
            You are about to remove the following inventory record and all its
            associated supply units from this product:
          </p>

          {/* Inventory Details Table */}
          <Table definition compact>
            <Table.Body>
              <Table.Row>
                <Table.Cell width={4}>Inventory ID</Table.Cell>
                <Table.Cell>
                  <strong>{inventoryToDelete.inventoryId}</strong>
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Title</Table.Cell>
                <Table.Cell>
                  <strong>{inventoryToDelete.inventoryTitle}</strong>
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>

          <Header as="h4" style={{ marginTop: "1.5em", marginBottom: "1em" }}>
            Associated Supply Units ({inventoryToDelete.units.length})
          </Header>

          {/* Supply Units Table */}
          <Table compact="very">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Supply Title</Table.HeaderCell>
                <Table.HeaderCell>UPC</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {inventoryToDelete.units.map((unit, index) => (
                <Table.Row key={index}>
                  <Table.Cell>{unit.supply_title}</Table.Cell>
                  <Table.Cell>{unit.upc}</Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Modal.Content>

        <Modal.Actions>
          <Button onClick={() => setShowDeleteInventoryModal(false)}>
            Cancel
          </Button>
          <Button
            negative
            icon
            labelPosition="right"
            onClick={() => {
              setSupplyUnits((prev) =>
                prev.filter(
                  (unit) => unit.inventory_id !== inventoryToDelete.inventoryId
                )
              );
              setShowDeleteInventoryModal(false);
            }}
          >
            Delete
            <i className="trash alternate icon" />
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
}
