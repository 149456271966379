import React, { useState, useCallback } from "react";
import * as Yup from "yup";
import { Formik, Form as FormikForm, useField } from "formik";
import {
  Form,
  Search,
  Grid,
  Button,
  Label,
  Icon,
  Divider,
  Segment,
  Message,
} from "semantic-ui-react";
import axios from "../../../apis/dvdlocator";
import { debounce } from "lodash";
import "./index.css";

// Custom form field component
const FormikField = ({ label, formSubmitted, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <Form.Field>
      <label>{label}</label>
      <Form.Input {...field} {...props} />
      {formSubmitted && meta.error ? (
        <Label basic color="red" pointing>
          {meta.error}
        </Label>
      ) : null}
    </Form.Field>
  );
};

export function ProductForm({ nextPage, product, setProduct, onClose }) {
  const [searchLoading, setSearchLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [searchError, setSearchError] = useState("");

  // Create a debounced search function
  const debouncedSearch = useCallback(
    debounce(async (value) => {
      if (!value) {
        setSearchResults([]);
        setSearchError("");
        return;
      }

      setSearchLoading(true);
      setSearchError("");

      try {
        const result = await axios.get(`/families?name=${value}`);
        setSearchResults(transformDataForResults(result.data));
      } catch (err) {
        console.log(err);
        setSearchError(
          err.response?.data?.message ||
            "An error occurred while searching. Please try again."
        );
        setSearchResults([]);
      } finally {
        setSearchLoading(false);
      }
    }, 500),
    []
  );

  const handleSearchChange = (e, { value }) => {
    setSearchValue(value);
    debouncedSearch(value);
  };

  const transformDataForResults = (families) => {
    return families.map((entry) => ({ id: entry.id, title: entry.family }));
  };

  const customResultRenderer = (result, families) => {
    const isSelected = families.some((f) => f.title === result.title);
    return (
      <div
        style={{
          opacity: isSelected ? 0.5 : 1,
          cursor: isSelected ? "not-allowed" : "pointer",
          padding: "8px",
          color: isSelected ? "#666" : "inherit",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <span>{result.title}</span>
          {isSelected && (
            <span style={{ fontSize: "0.8em", color: "#666" }}>
              (Already Added)
            </span>
          )}
        </div>
      </div>
    );
  };

  const validationSchema = Yup.object({
    title: Yup.string().min(8).max(250).required("Title is required"),
    asin: Yup.string().length(10).required("ASIN is required"),
    partNumber: Yup.string()
      .matches(/^\d+([+-]\d+)*$/, {
        message:
          "Part Number must contain only numbers separated by '+' or '-'",
        excludeEmptyString: true,
      })
      .required("Part Number is required"),
  });

  return (
    <>
      <Formik
        initialValues={product}
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          setProduct(values);
          setSubmitting(false);
          nextPage();
        }}
      >
        {({
          handleSubmit,
          isSubmitting,
          submitCount,
          values,
          setFieldValue,
        }) => {
          const handleResultSelect = (e, { result }) => {
            if (values.families.some((f) => f.title === result.title)) {
              return;
            }

            setFieldValue("families", [...values.families, result]);
            setSearchValue("");
          };

          const handleRemoveFamily = (familyId) => {
            setFieldValue(
              "families",
              values.families.filter((f) => f.id !== familyId)
            );
          };

          return (
            <Form as={FormikForm} onSubmit={handleSubmit}>
              <Segment basic>
                <FormikField
                  label="ASIN"
                  name="asin"
                  placeholder="Enter ASIN"
                  formSubmitted={submitCount > 0}
                />
                <FormikField
                  label="Title"
                  name="title"
                  placeholder="Enter Title"
                  formSubmitted={submitCount > 0}
                />
                <FormikField
                  label="Part Number"
                  name="partNumber"
                  placeholder="Enter Part Number"
                  formSubmitted={submitCount > 0}
                />
                <Form.Field>
                  <label>Families</label>
                  <Search
                    fluid
                    input={{ fluid: true }}
                    loading={searchLoading}
                    placeholder="Search Families"
                    onResultSelect={handleResultSelect}
                    onSearchChange={handleSearchChange}
                    results={searchResults}
                    resultRenderer={(result) =>
                      customResultRenderer(result, values.families)
                    }
                    minCharacters={2}
                    value={searchValue}
                    className="scrolling-search"
                  />
                  {searchError && (
                    <Message
                      error
                      content={searchError}
                      style={{ marginTop: "5px" }}
                    />
                  )}
                  <div style={{ marginTop: "10px" }}>
                    {values.families.map((family) => (
                      <Label
                        key={family.id}
                        style={{ marginRight: "5px", marginBottom: "5px" }}
                      >
                        {family.title}
                        <Icon
                          name="delete"
                          onClick={() => handleRemoveFamily(family.id)}
                        />
                      </Label>
                    ))}
                  </div>
                </Form.Field>
              </Segment>

              <Divider />
              <Segment basic>
                <Grid>
                  <Grid.Row>
                    <Grid.Column width={8}>
                      <Button type="button" onClick={onClose} floated="left">
                        Cancel
                      </Button>
                    </Grid.Column>
                    <Grid.Column width={8}>
                      <Button
                        type="submit"
                        floated="right"
                        primary
                        disabled={isSubmitting}
                      >
                        Next
                      </Button>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Segment>
            </Form>
          );
        }}
      </Formik>
    </>
  );
}
