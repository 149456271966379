import React from "react";
import { connect } from "react-redux";
import _ from "lodash";
import {
  Form,
  Dropdown,
  Segment,
  Grid,
  Header,
  Button,
} from "semantic-ui-react";
import { reduxForm } from "redux-form";
import Auth from "@aws-amplify/auth";
import dvdlocator from "../../apis/dvdlocator";
import ColumnReorder from "./ColumnReorder";
import { masterProductColumnSet } from "../database/Pricetool/ptProductColumns";
import { masterInventoryColumnSet } from "../database/Pricetool/ptInventoryColumns";
import {
  extractTooltipOrAltText,
  formatSQLColumnString,
} from "../utilities/formatters/columnSetForm";
import "./columnSets.css";
import { getColumnSetsForUserId } from "../../apis/columnSets";

class ColumnSets extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      productColumnsFirst: true,
      items: [],
      productColumns: [],
      inventoryColumns: [],
      selectedColumnSet: "",
      selectedColumn: "",
      selectedShareRecipient: "",
      usernames: [],
      columnSets: [],
      masterColumnSet: [...masterProductColumnSet, ...masterInventoryColumnSet],
    };

    this.grid = 2;
    this.dropdownStyle = { marginTop: "10px", marginBottom: "10px" };
  }

  componentDidUpdate() {
    console.log(this.state.columnSets);
  }

  async loadColumnSets() {
    const columnSets = await getColumnSetsForUserId(this.props.auth.userId);
    this.setState({ columnSets });
  }

  async loadUsernames() {
    try {
      const response = await dvdlocator.get("/nosql/get-usernames");
      const data = response.data;
      this.setState({ usernames: data });
    } catch (error) {
      // Handle any errors here
      console.error("Error fetching usernames:", error);
    }
  }

  async componentDidMount() {
    await Auth.currentAuthenticatedUser();

    // check that login request completed first
    if (this.props.auth.userId) {
      await this.loadColumnSets(this.props.auth.userId);
    }

    await this.loadUsernames();
  }

  dropdownOptions = () => {
    return _.map(
      this.state.masterColumnSet.filter((item) => item.title),
      (col) => {
        let fieldName = extractTooltipOrAltText(col.title);
        if (!fieldName) {
          fieldName = formatSQLColumnString(col.data);
        }
        return {
          key: col.data,
          text: fieldName,
          value: col.data,
          disabled: this.state.items.some((c) => c.id === col.data),
        };
      }
    );
  };

  getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    padding: this.grid * 2,
    borderRadius: "3px",
    margin: `0 0 ${this.grid}px 0`,
    width: "125px",
    flexShrink: 0,

    background: isDragging ? "lightgreen" : "lightgrey",

    // styles we need to apply on draggables
    ...draggableStyle,
  });

  getListStyle = (isDraggingOver, itemsLength) => ({
    background: isDraggingOver ? "lightblue" : "none",
    display: "flex",
    padding: this.grid,
    overflowX: "scroll",
    gap: "5px",
  });

  removeProductColumn = (id) => {
    this.setState({
      productColumns: this.state.productColumns.filter((col) => col.id !== id),
    });
  };

  removeInventoryColumn = (id) => {
    this.setState({
      inventoryColumns: this.state.inventoryColumns.filter(
        (col) => col.id !== id
      ),
    });
  };

  addProductColumn(column) {
    const exists = this.state.productColumns.find(
      (col) => col.id === column.data
    );

    if (exists) return;

    this.setState({
      productColumns: [
        ...this.state.productColumns,
        { id: column.data, content: column.title },
      ],
    });
  }

  addInventoryColumn(column) {
    const exists = this.state.inventoryColumns.find(
      (col) => col.id === column.data
    );

    if (exists) return;

    this.setState({
      inventoryColumns: [
        ...this.state.inventoryColumns,
        { id: column.data, content: column.title },
      ],
    });
  }

  addColumn = () => {
    const col = this.state.masterColumnSet.find(
      (col) => col.data === this.state.selectedColumn
    );
    console.log(col);

    if (
      masterProductColumnSet.find((masterCol) => masterCol.data === col.data)
    ) {
      this.addProductColumn(col);
    } else {
      this.addInventoryColumn(col);
    }
  };

  // data generator
  getSelectedColsetRenderableList = (columnSetName) => {
    const colSet = this.state.columnSets.find(
      (set) => set.columnSetName === columnSetName
    );

    console.log(colSet);

    return colSet.columns
      .map((name) => {
        const column = this.state.masterColumnSet.find(
          (row) => row.data === name
        );

        // nulling out columns no longer defined in master set
        // these would be artifacts from db schema changes
        if (!column) return null;

        return {
          id: column.data,
          content: column.title,
        };
      })
      .filter((col) => col);
  };

  deleteColumnSet = () => {
    if (!this.state.selectedColumnSet) {
      alert("You must select a column set");
    } else {
      const accepted = window.confirm(
        `Do you want to delete the column set '${this.state.selectedColumnSet}'?`
      );

      if (!accepted) return;

      dvdlocator
        .delete(
          `/nosql/${this.props.auth.userId}/column-sets/${this.state.selectedColumnSet}`
        )
        .then(() => {
          const colSetToDelete = this.state.selectedColumnSet;
          const cleanedColumnSets = this.state.columnSets.filter(
            (set) => set.colSetName === colSetToDelete
          );
          this.setState({
            selectedColumnSet: "",
            items: [],
            columnSets: cleanedColumnSets,
          });
          alert(`${colSetToDelete} successfully deleted!`);
        })
        .catch((err) => alert(err.message));
    }
  };

  // load a new colSet into the colReorder panel
  displayColumnSet = (columnSetName) => {
    const items = this.getSelectedColsetRenderableList(columnSetName);
    this.setState({
      selectedColumnSet: columnSetName,
      productColumns: items.filter((col) =>
        masterProductColumnSet.find((masterCol) => masterCol.data === col.id)
      ),
      inventoryColumns: items.filter((col) =>
        masterInventoryColumnSet.find((masterCol) => masterCol.data === col.id)
      ),
    });
  };

  onShareSubmit = () => {
    if (!this.state.selectedColumnSet) {
      alert("You must select a column set");
    } else if (!this.state.selectedShareRecipient) {
      alert("You must select a user to share column set with");
    } else {
      dvdlocator
        .post("/nosql/share-column-sets", {
          sender: this.props.auth.userId,
          recipient: this.state.selectedShareRecipient,
          columnSet: this.state.selectedColumnSet,
        })
        .then((response) => alert(response.data));
    }
  };

  // push updated colSet to dynamodb
  onSaveSubmit = async () => {
    if (!this.state.selectedColumnSet) {
      alert("You must select a column set");
    } else {
      const accepted = window.confirm(
        `Are you sure you want to save these columns for column set '${this.state.selectedColumnSet}'?`
      );

      if (!accepted) return;

      await this.saveCurrentColumnSet(this.state.selectedColumnSet);
    }
  };

  // push new colSet to dynamodb
  onNewSubmit = async () => {
    let colSetName = prompt("Enter New Column Set Name");

    if (!colSetName) return alert("Must provide a name.");

    colSetName = colSetName.trim();

    if (
      this.state.columnSets.some(
        (colSet) => colSet.columnSetName === colSetName
      )
    ) {
      return alert(`Column set ${colSetName} already exists. Please try again`);
    }

    await this.saveCurrentColumnSet(colSetName);
    this.setState({ selectedColumnSet: colSetName });
  };

  /**
   * Makes the backend API request to save the current column set selection.
   * @param {string} colSetName
   */
  saveCurrentColumnSet = async (colSetName) => {
    const columns = [
      ...this.state.productColumns,
      ...this.state.inventoryColumns,
    ];

    // Creating array containing all users column sets
    const updatedColumnSets = [
      ...this.state.columnSets.filter(
        (item) => item.columnSetName !== colSetName
      ),
      { columnSetName: colSetName, columns: columns.map((col) => col.id) },
    ];

    // Prepare request body
    const requestBody = {
      username: this.props.auth.userId,
      columnSets: updatedColumnSets,
    };

    console.log(requestBody);
    try {
      await dvdlocator.put("/nosql/column-sets", requestBody);

      this.setState({ columnSets: updatedColumnSets });
      alert("Column set saved successfully");
    } catch (err) {
      console.log(err);
      alert("Error saving column set");
    }
  };

  reorderInventoryColumns = (items) => {
    this.setState({ inventoryColumns: items });
  };

  reorderProductColumns = (items) => {
    this.setState({ productColumns: items });
  };

  render() {
    // wait until data loaded to render
    if (!_.isEmpty(this.state.columnSets) && this.props.auth.userId) {
      return (
        <React.Fragment>
          <Segment padded className="grid-box">
            <Grid columns={2} stackable textAlign="center">
              <Grid.Row verticalAlign="middle">
                {/* Select Column Set Form */}
                <Grid.Column>
                  <div>
                    <div className="ui label">Select Column Set</div>
                    <Form.Field>
                      <Dropdown
                        style={this.dropdownStyle}
                        search
                        selection
                        value={this.state.selectedColumnSet}
                        onChange={(param, data) =>
                          this.displayColumnSet(data.value)
                        }
                        placeholder={"Select column set"}
                        options={this.state.columnSets.map((colSet) => ({
                          key: colSet.columnSetName,
                          text: colSet.columnSetName,
                          value: colSet.columnSetName,
                        }))}
                      />
                    </Form.Field>
                  </div>

                  <button
                    onClick={this.onSaveSubmit}
                    className="button ui blue inverted"
                    disabled={
                      this.state.productColumns.length < 1 &&
                      this.state.inventoryColumns.length < 1
                    }
                  >
                    Save
                  </button>
                  <button
                    onClick={this.onNewSubmit}
                    disabled={
                      this.state.productColumns.length < 1 &&
                      this.state.inventoryColumns.length < 1
                    }
                    className="button ui blue inverted"
                  >
                    New
                  </button>
                  <button
                    onClick={this.deleteColumnSet}
                    disabled={!this.state.selectedColumnSet}
                    className="button ui red"
                    title="Delete selected column set"
                  >
                    Delete
                  </button>
                </Grid.Column>

                {/* Share Column Set Form */}
                <Grid.Column>
                  <div>
                    <div className="ui label">Share Column Set</div>
                    <Form.Field>
                      <Dropdown
                        style={this.dropdownStyle}
                        selection
                        search
                        value={this.state.selectedShareRecipient}
                        onChange={(param, data) =>
                          this.setState({ selectedShareRecipient: data.value })
                        }
                        placeholder={"Select user"}
                        options={this.state.usernames.map((name) => {
                          return {
                            key: name,
                            text: name,
                            value: name,
                          };
                        })}
                      />
                    </Form.Field>
                  </div>
                  <button
                    onClick={this.onShareSubmit}
                    disabled={!this.state.selectedShareRecipient}
                    className="button ui blue inverted"
                  >
                    Share
                  </button>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
          <Segment basic>
            <Grid verticalAlign="middle">
              <Grid.Row>
                <Grid.Column width={2}>
                  <Header size="medium">Product Columns</Header>
                </Grid.Column>
                <Grid.Column width={2}>
                  <Form.Field>
                    <Dropdown
                      style={this.dropdownStyle}
                      search={handleSearchChange}
                      selection
                      fluid
                      value={this.state.selectedProductColumn}
                      onChange={(_, data) => {
                        this.setState({
                          selectedProductColumn: data.value,
                        });
                      }}
                      placeholder="Select product column"
                      options={_.map(
                        masterProductColumnSet.filter((item) => item.title),
                        (col) => {
                          let fieldName = extractTooltipOrAltText(col.title);
                          if (!fieldName) {
                            fieldName = formatSQLColumnString(col.data);
                          }
                          return {
                            key: col.data,
                            text: fieldName,
                            value: col.data,
                            disabled: this.state.productColumns.some(
                              (c) => c.id === col.data
                            ),
                          };
                        }
                      )}
                    />
                  </Form.Field>
                </Grid.Column>
                <Grid.Column width={2}>
                  <Button
                    inverted
                    primary
                    fluid
                    disabled={!this.state.selectedProductColumn}
                    onClick={() => {
                      const col = masterProductColumnSet.find(
                        (col) => col.data === this.state.selectedProductColumn
                      );
                      this.addProductColumn(col);
                    }}
                  >
                    Add Product Column
                  </Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <ColumnReorder
              items={this.state.productColumns}
              removeColumn={this.removeProductColumn}
              reorderColumns={this.reorderProductColumns}
              getItemStyle={this.getItemStyle}
              getListStyle={this.getListStyle}
            />
          </Segment>

          <Segment basic>
            <Grid verticalAlign="middle">
              <Grid.Row>
                <Grid.Column width={2}>
                  <Header size="medium">Inventory Columns</Header>
                </Grid.Column>
                <Grid.Column width={2}>
                  <Form.Field>
                    <Dropdown
                      style={this.dropdownStyle}
                      search={handleSearchChange}
                      selection
                      fluid
                      value={this.state.selectedInventoryColumn}
                      onChange={(_, data) => {
                        this.setState({
                          selectedInventoryColumn: data.value,
                        });
                      }}
                      placeholder="Select inventory column"
                      options={_.map(
                        masterInventoryColumnSet.filter((item) => item.title),
                        (col) => {
                          let fieldName = extractTooltipOrAltText(col.title);
                          if (!fieldName) {
                            fieldName = formatSQLColumnString(col.data);
                          }
                          return {
                            key: col.data,
                            text: fieldName,
                            value: col.data,
                            disabled: this.state.inventoryColumns.some(
                              (c) => c.id === col.data
                            ),
                          };
                        }
                      )}
                    />
                  </Form.Field>
                </Grid.Column>
                <Grid.Column width={2}>
                  <Button
                    inverted
                    primary
                    fluid
                    disabled={!this.state.selectedInventoryColumn}
                    onClick={() => {
                      const col = masterInventoryColumnSet.find(
                        (col) => col.data === this.state.selectedInventoryColumn
                      );
                      this.addInventoryColumn(col);
                    }}
                  >
                    Add Inventory Column
                  </Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <ColumnReorder
              items={this.state.inventoryColumns}
              removeColumn={this.removeInventoryColumn}
              reorderColumns={this.reorderInventoryColumns}
              getItemStyle={this.getItemStyle}
              getListStyle={this.getListStyle}
            />
          </Segment>
        </React.Fragment>
      );
    } else {
      return (
        <div className="ui active transition visible dimmer">
          <div className="content">
            <div className="ui loader"></div>
          </div>
        </div>
      );
    }
  }
}

const handleSearchChange = (options, value) => {
  return options.filter((option) => {
    return option.text.toLowerCase().includes(value.toLowerCase());
  });
};

// called by redux-form on any user interaction. validates input
// and returns errors if necessary
const validate = (formValues) => {
  const errors = {};

  return errors;
};

const mapStateToProps = (state) => {
  return { auth: state.auth };
};

export default reduxForm({
  form: "colSetForm",
  validate,
})(connect(mapStateToProps)(ColumnSets));
