import React, { useEffect, useState } from "react";
import { Modal } from "semantic-ui-react";
import { ProductForm } from "./ProductFormPage";
import { ReviewSelectionPage } from "./ReviewSelectionPage";
import { AddSupplyUnitPage } from "./AddSupplyUnitPage";

export const CreateProductWizard = ({
  onClose,
  preselectedSupplyUnits,
  productToDuplicate,
}) => {
  const [page, setPage] = useState(1);
  const [product, setProduct] = useState({
    title: "",
    asin: "",
    partNumber: "",
    families: [],
  });
  const [duplicateInitialLoadComplete, setDuplicateInitialLoadComplete] =
    useState(false);
  const [supplyUnits, setSupplyUnits] = useState([]);

  useEffect(() => {
    if (productToDuplicate) {
      setProduct({
        duplicateProductId: productToDuplicate.product_id,
        title: productToDuplicate.title,
        asin: "",
        partNumber: productToDuplicate.partNumber,
        families: productToDuplicate.families,
      });
    }
  }, [productToDuplicate]);

  const nextPage = () => setPage((curr) => (curr += 1));
  const prevPage = () => setPage((curr) => (curr -= 1));

  const renderPageContent = () => {
    switch (page) {
      case 1:
        return (
          <ProductForm
            product={product}
            setProduct={setProduct}
            nextPage={nextPage}
            onClose={onClose}
          />
        );
      case 2:
        return (
          <AddSupplyUnitPage
            duplicatingProduct={productToDuplicate !== null}
            preselectedSupplyUnits={preselectedSupplyUnits}
            duplicateInitialLoadComplete={duplicateInitialLoadComplete}
            setDuplicateInitialLoadComplete={setDuplicateInitialLoadComplete}
            product={product}
            setProduct={setProduct}
            nextPage={nextPage}
            prevPage={prevPage}
            setSupplyUnits={setSupplyUnits}
            supplyUnits={supplyUnits}
          />
        );
      case 3:
        return (
          <ReviewSelectionPage
            prevPage={prevPage}
            product={product}
            setProduct={setProduct}
            supplyUnits={supplyUnits}
            onClose={onClose}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Modal open onClose={onClose} closeOnDimmerClick={true} size="large">
      <Modal.Header>Create New Product</Modal.Header>
      {renderPageContent()}
    </Modal>
  );
};
