import React, { Fragment } from "react";
import { debounce } from "lodash";
import {
  Segment,
  Button,
  Icon,
  Input,
  Grid,
  Tab,
  Checkbox,
} from "semantic-ui-react";
import Auth from "@aws-amplify/auth";
import { connect } from "react-redux";
import { openLinks } from "./buttonHandlers/buttonHandlers";
import { addNotification } from "../../../redux/actions/notificationActions";
import { masterProductColumnSet } from "./ptProductColumns";
import { masterInventoryColumnSet } from "./ptInventoryColumns";
import ColumnSetForm from "../ColumnSet/ColumnSetForm";
import PricetoolSidebar from "./PricetoolSidebar";
import PricetoolLog from "./PricetoolLog";
import ModalSwitch from "./buttonHandlers/modals/ModalSwitch";
import MessageModal from "../../MessageModal";
import FilterOptions from "./FilterOptions";
import ActiveFilters from "./ActiveFilters";
import FloatingLabelButton from "../../buttons/FloatingLabel";
import { renderDate } from "../../formatting/dateFormat";
import ProductTable from "./ProductTable";
import InventoryTable from "./InventoryTable";
import { getColumnSetsForUserId } from "../../../apis/columnSets";
import "./Pricetool.css";

const DefaultTabSelector = ({ currentTab, onSetDefault }) => {
  const isDefault = localStorage.getItem("defaultTab") === currentTab;

  if (isDefault) {
    return (
      <span
        style={{ fontStyle: "italic", color: "green" }}
        title={"This tab is your default"}
      >
        <Icon name="check" /> Default tab
      </span>
    );
  } else {
    return (
      <Checkbox
        label="Default tab"
        checked={isDefault}
        onChange={() => {
          if (!isDefault) {
            onSetDefault(currentTab);
          }
        }}
        title="Set this tab as your default"
      />
    );
  }
};

class Pricetool extends React.Component {
  constructor(props) {
    super(props);

    this.searchDelay = null;
    this.barCodeType = null;
    this.rowData = [];
    this.datatable = null;
    const defaultTab = localStorage.getItem("defaultTab") || "All";
    const smallTables = defaultTab === "All" || defaultTab === undefined;
    const tableHeight = smallTables
      ? window.innerHeight / 2 - 200
      : window.innerHeight - 200;

    this.state = {
      // datatable view state
      viewName: defaultTab,
      inventoryTableOpen: true,
      productTableOpen: true,
      productTableHeight: tableHeight,
      inventoryTableHeight: tableHeight,

      leftSidebarVisible: false,
      rightSidebarVisible: false,
      modalName: "",
      selectedRowsArray: [],
      appendStatus: false,
      appendErrors: [],
      appendColumnNames: [],
      appendTableNames: [],
      isLoading: false,
      modalIsActive: false,
      msg: "",
      labelModalIsActive: false,
      isOpenMessageModal: false,
      searchValue: this.props.match.params.search
        ? this.props.match.params.search
        : "",
      debouncedSearchValue: this.props.match.params.search
        ? this.props.match.params.search
        : "",
      contextMenu: {
        display: false,
        event: null,
        rowsPreselected: false,
        modal: "",
        products: [],
        loading: false,
      },

      displayFilterOptions: false,
      filterOptions: {
        retired: false,
      },

      // Column set state
      currentColumnSet: null,
      currentColumnSetColumns: [
        ...masterProductColumnSet,
        masterInventoryColumnSet,
      ],
      columnSets: [],

      outputLog: [],
      outputLogClearedTimestamp: null,

      render: true,
    };

    this.dataTablesRef = React.createRef();
  }

  // New method to set default tab
  setDefaultTab = (tabName) => {
    // Store in local storage
    localStorage.setItem("defaultTab", tabName);

    // Update state to reflect the default view
    this.setState({ viewName: tabName }, () => {
      // Optional: Add a notification to confirm
      this.props.addNotification({
        type: "success",
        message: `${tabName} tab set as default`,
      });
    });
  };

  toggleTableView = (viewName) => {
    switch (viewName) {
      case "Product":
        this.setState({
          viewName: "Product",
          productTableVisible: true,
          inventoryTableVisible: false,
          productTableHeight: window.innerHeight,
        });
        break;

      case "Inventory":
        this.setState({
          viewName: "Inventory",
          productTableVisible: false,
          inventoryTableVisible: true,
          inventoryTableHeight: window.innerHeight,
        });
        break;

      case "All":
        this.setState({
          viewName: "All",
          productTableVisible: true,
          inventoryTableVisible: true,
          productTableHeight: window.innerHeight / 2 - 200,
          inventoryTableHeight: window.innerHeight / 2 - 200,
        });
        break;

      default:
        // Optional: handle unexpected input
        console.warn(`Unexpected view name: ${viewName}`);
    }
  };

  clearOutputLog = () => {
    this.setState((prev) => ({
      outputLogClearedTimestamp: renderDate(Math.floor(Date.now() / 1000)),
      outputLog: prev.outputLog.filter(
        (_, i) => i === prev.outputLog.length - 1
      ),
    }));
  };

  async componentDidMount() {
    await Auth.currentAuthenticatedUser();

    const columnSets = await getColumnSetsForUserId(this.props.auth.userId);

    this.setState({ columnSets: columnSets });

    if (this.props.match.params.colset) {
      this.setColumnSet(this.props.match.params.colset);
    }
  }

  // search field onChange event handler
  onChangeSearch = (e) => {
    const { value } = e.target;

    const searchValue = value;
    this.setState({ searchValue });

    clearTimeout(this.searchDelay);

    this.searchDelay = setTimeout(() => {
      this.setState({ debouncedSearchValue: value });
    }, 2000);
  };

  handleSubmit = (e) => {
    // if user has pressed the enter key, trigger search immediately
    if (e.key === "Enter") {
      clearTimeout(this.searchDelay);
      this.displayLoading();
      this.datatable.draw();
    }
  };

  // changes the state of the loading icon on the application after 1 second of inactivity from the user
  displayLoading = debounce(() => {
    this.setState({ isLoading: true });
  }, 1000);

  /**
   * Sets the current column set state.
   * @param {string} columnSet
   */
  setColumnSet = (columnSetName) => {
    const columnSet = this.state.columnSets.find(
      (set) => set.columnSetName === columnSetName
    );

    this.setState({
      currentColumnSetColumns: columnSet.columns,
      currentColumnSet: columnSetName,
    });
  };

  // abstracts openLinks function to avoid having to pass a ref to the left sidebar
  openPricetoolLink = (store) => {
    if (this.dataTablesRef.current) {
      openLinks(this.dataTablesRef.current.dataTable, store);
    }
  };

  filterCount() {
    let count = 0;
    Object.values(this.state.filterOptions).forEach((val) =>
      val ? (count += 1) : null
    );
    return count;
  }

  filtersAreActive() {
    return Object.values(this.state.filterOptions).some((value) => {
      if (value) return true;
      else return false;
    });
  }

  closeFormModal = () => {
    this.setState({
      modalName: "",
      selectedRowsArray: [],
    });
  };

  render() {
    const { searchValue, columnSets, outputLog, outputLogClearedTimestamp } =
      this.state;

    const panes = [
      {
        menuItem: "All",
        render: () => (
          <Tab.Pane>
            <div>
              <ProductTable
                filterOptions={this.state.filterOptions}
                match={this.props.match}
                tableHeight={this.state.productTableHeight}
                currentColumnSet={this.state.currentColumnSet}
                currentColumnSetColumns={this.state.currentColumnSetColumns}
                searchValue={this.state.debouncedSearchValue}
                appendOutputLog={(newLog) =>
                  this.setState({
                    outputLog: [...this.state.outputLog, newLog],
                  })
                }
              />
              <InventoryTable
                match={this.props.match}
                tableHeight={this.state.inventoryTableHeight}
                currentColumnSet={this.state.currentColumnSet}
                currentColumnSetColumns={this.state.currentColumnSetColumns}
                searchValue={this.state.debouncedSearchValue}
                appendOutputLog={(newLog) =>
                  this.setState({
                    outputLog: [...this.state.outputLog, newLog],
                  })
                }
              />
            </div>
          </Tab.Pane>
        ),
      },
      {
        menuItem: "Product",
        render: () => (
          <Tab.Pane>
            <ProductTable
              filterOptions={this.state.filterOptions}
              match={this.props.match}
              tableHeight={this.state.productTableHeight}
              currentColumnSet={this.state.currentColumnSet}
              currentColumnSetColumns={this.state.currentColumnSetColumns}
              searchValue={this.state.debouncedSearchValue}
              appendOutputLog={(newLog) =>
                this.setState({
                  outputLog: [...this.state.outputLog, newLog],
                })
              }
            />
          </Tab.Pane>
        ),
      },
      {
        menuItem: "Inventory",
        render: () => (
          <Tab.Pane>
            <InventoryTable
              match={this.props.match}
              tableHeight={this.state.inventoryTableHeight}
              currentColumnSet={this.state.currentColumnSet}
              currentColumnSetColumns={this.state.currentColumnSetColumns}
              searchValue={this.state.debouncedSearchValue}
              appendOutputLog={(newLog) =>
                this.setState({
                  outputLog: [...this.state.outputLog, newLog],
                })
              }
            />
          </Tab.Pane>
        ),
      },
    ];

    return (
      <Fragment>
        <FilterOptions
          filterOptions={this.state.filterOptions}
          updateFilters={(filters) => {
            this.setState({ filterOptions: filters });
          }}
          isOpen={this.state.displayFilterOptions}
          onClose={() => this.setState({ displayFilterOptions: false })}
        />
        <MessageModal
          open={this.state.isOpenMessageModal}
          msg={this.state.msg}
          setOpen={this.setModalOpen}
          type="copy"
        />
        <ModalSwitch
          closeModal={this.closeFormModal}
          selectedRows={this.state.selectedRowsArray}
          modalName={this.state.modalName}
          appendStatus={this.state.appendStatus}
          appendErrors={this.state.appendErrors}
          columnNames={this.state.appendColumnNames}
          submitAppendForm={this.submitAppendForm}
        />

        <PricetoolSidebar
          leftSidebarVisible={this.state.leftSidebarVisible}
          rightSidebarVisible={this.state.rightSidebarVisible}
          closeLeftSidebar={() =>
            this.setState({
              leftSidebarVisible: false,
            })
          }
          closeRightSidebarToggle={() =>
            this.setState({
              rightSidebarVisible: false,
            })
          }
          clearSidebar={this.clearOutputLog}
          openPricetoolLink={this.openPricetoolLink}
          content={
            <PricetoolLog
              content={outputLog}
              clearedTimestamp={outputLogClearedTimestamp}
            />
          }
        >
          <Segment>
            <Grid>
              <Grid.Row columns={3}>
                <Grid.Column width={6}>
                  <Button
                    icon
                    onMouseEnter={() => {
                      this.setState({ leftSidebarVisible: true });
                    }}
                  >
                    <Icon name="list" />
                  </Button>
                  <Input
                    placeholder="Search ..."
                    value={searchValue}
                    onChange={this.onChangeSearch}
                    onKeyDown={this.handleSubmit}
                    autoComplete="off"
                    style={{ margin: "0 12px" }}
                  />
                  <FloatingLabelButton
                    label="More Filters"
                    labelValue={this.filterCount()}
                    color={this.filtersAreActive() ? "blue" : null}
                    onClick={() =>
                      this.setState({ displayFilterOptions: true })
                    }
                    style={{ marginRight: "10px" }}
                  />
                  {this.filtersAreActive() && (
                    <ActiveFilters filters={this.state.filterOptions} />
                  )}
                </Grid.Column>

                <Grid.Column width={4}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "52px",
                    }}
                  >
                    <DefaultTabSelector
                      currentTab={this.state.viewName}
                      onSetDefault={this.setDefaultTab}
                    />
                  </div>
                </Grid.Column>

                <Grid.Column width={6}>
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <ColumnSetForm
                      columnSets={columnSets}
                      handleReorder={this.setColumnSet}
                    />

                    <div style={{ marginLeft: "12px" }}>
                      <Button
                        icon
                        onClick={() => {
                          this.state.rightSidebarVisible
                            ? this.setState({ buttonShift: 0 })
                            : this.setState({ buttonShift: 350 });
                          this.setState({
                            rightSidebarVisible:
                              !this.state.rightSidebarVisible,
                          });
                        }}
                        style={{
                          transform: `translate(${-this.state.buttonShift}px)`,
                          transition: "0.5s",
                        }}
                      >
                        <Icon name="list" />
                      </Button>
                    </div>
                  </div>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>

          <Tab
            panes={panes}
            activeIndex={
              this.state.viewName === "All"
                ? 0
                : this.state.viewName === "Product"
                ? 1
                : this.state.viewName === "Inventory"
                ? 2
                : 0 // Default to first tab if no match
            }
            onTabChange={(e, data) => {
              // Map tab index to view names
              const viewNames = ["All", "Product", "Inventory"];
              this.toggleTableView(viewNames[data.activeIndex]);
            }}
          />
        </PricetoolSidebar>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  const { auth } = state;
  return { auth };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addNotification: (notification) => dispatch(addNotification(notification)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Pricetool);
