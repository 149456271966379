import React, { useEffect, useState } from "react";
import { Segment } from "semantic-ui-react";
import { SearchAddPage } from "./SearchAddPage";
import { AssignPartNumberPage } from "./AssignPartNumberPage";
import { InventoryPoolPage } from "./InventoryPoolPage";
import { InventoryTitlePage } from "./InventoryTitlePage";

export const AddSupplyUnitWizard = ({
  supplyUnits,
  setSupplyUnits,
  setAddingSupplyUnit,
  product,
  preselectedSupplyUnits,
}) => {
  const [page, setPage] = useState(1);
  const [wizardSupplyUnits, setWizardSupplyUnits] = useState([]);

  const nextPage = () => setPage((curr) => (curr += 1));
  const prevPage = () => setPage((curr) => (curr -= 1));

  useEffect(() => {
    if (preselectedSupplyUnits) {
      setPage(2);
      setWizardSupplyUnits(preselectedSupplyUnits);
    }
  }, [preselectedSupplyUnits]);

  const renderPageContent = () => {
    switch (page) {
      case 1:
        return (
          <SearchAddPage
            nextPage={nextPage}
            masterSupplyUnits={supplyUnits}
            supplyUnits={wizardSupplyUnits}
            setSupplyUnits={setWizardSupplyUnits}
            onClose={() => setAddingSupplyUnit(false)}
          />
        );
      case 2:
        return (
          <AssignPartNumberPage
            prevPage={prevPage}
            nextPage={nextPage}
            supplyUnits={wizardSupplyUnits}
            setSupplyUnits={setWizardSupplyUnits}
            productPartNumber={product.partNumber}
          />
        );
      case 3:
        return (
          <InventoryPoolPage
            prevPage={prevPage}
            nextPage={nextPage}
            supplyUnits={wizardSupplyUnits}
            setSupplyUnits={setWizardSupplyUnits}
          />
        );
      case 4:
        return (
          <InventoryTitlePage
            prevPage={prevPage}
            nextPage={nextPage}
            supplyUnits={wizardSupplyUnits}
            setSupplyUnits={setSupplyUnits}
            closeForm={() => setAddingSupplyUnit(false)}
          />
        );
      default:
        return null;
    }
  };

  return <Segment>{renderPageContent()}</Segment>;
};
