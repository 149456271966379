import React, { useState } from "react";
import { AddSupplyUnitWizard } from "./AddSupplyUnitWizard/AddSupplyUnitWizard";
import {
  Table,
  Grid,
  Header,
  Button,
  Divider,
  Segment,
  Message,
  Loader,
} from "semantic-ui-react";
import { HierarchicalSupplyUnitsList } from "./HierchichalSupplyUnitDisplay";
import axios from "../../../apis/dvdlocator";

export function ReviewSelectionPage({
  product,
  onClose,
  prevPage,
  setSupplyUnits,
  supplyUnits,
}) {
  const [addingSupplyUnit, setAddingSupplyUnit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [createdProduct, setCreatedProduct] = useState({});

  const createProduct = async () => {
    try {
      const res = await axios.post("product", { product });
      setCreatedProduct({ ...product, id: res.data.id });
      return res.data.id;
    } catch (err) {
      throw new Error("Failed to create product: " + err.message);
    }
  };

  const linkProductInventory = async (productId) => {
    const uniqueInventoryRecords = Array.from(
      supplyUnits.reduce((map, unit) => {
        map.set(unit.inventory_id, {
          inventoryId: unit.inventory_id,
          partNumber: unit.part_number,
        });
        return map;
      }, new Map()),
      ([_, value]) => value
    );

    if (uniqueInventoryRecords.length === 0) return;

    try {
      await axios.post(`product/${productId}/inventory`, {
        inventoryRecords: uniqueInventoryRecords,
      });
    } catch (err) {
      throw new Error("Failed to link inventory: " + err.message);
    }
  };

  const submitForm = async () => {
    setIsLoading(true);
    setError(null);

    try {
      const productId = await createProduct();
      if (productId) {
        await linkProductInventory(productId);
        setSuccess(true);
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  if (success && createdProduct) {
    return (
      <Segment basic>
        <Message positive>
          <Message.Header>Product Successfully Created!</Message.Header>
        </Message>
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>ASIN</Table.HeaderCell>
              <Table.HeaderCell>Title</Table.HeaderCell>
              <Table.HeaderCell>Part Number</Table.HeaderCell>
              <Table.HeaderCell>Families</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.Cell>{createdProduct.asin}</Table.Cell>
              <Table.Cell>{createdProduct.title}</Table.Cell>
              <Table.Cell>{createdProduct.partNumber}</Table.Cell>
              <Table.Cell>
                {createdProduct.families.map((fam) => fam.title).join(", ")}
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
        <Divider hidden />
        <Segment>
          <Button positive onClick={() => onClose(true)}>
            Close
          </Button>
        </Segment>
      </Segment>
    );
  }

  return (
    <>
      <Segment basic>
        {error && (
          <Message negative>
            <Message.Header>Error</Message.Header>
            <p>{error}</p>
          </Message>
        )}

        <p style={{ color: "gray" }}>
          Review your selections and click submit to create this product.
        </p>
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>ASIN</Table.HeaderCell>
              <Table.HeaderCell>Title</Table.HeaderCell>
              <Table.HeaderCell>Part Number</Table.HeaderCell>
              <Table.HeaderCell>Families</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.Cell>{product.asin}</Table.Cell>
              <Table.Cell>{product.title}</Table.Cell>
              <Table.Cell>{product.partNumber}</Table.Cell>
              <Table.Cell>
                {product.families.map((fam) => fam.title).join(", ")}
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
        <Divider section />
        <Grid>
          <Grid.Row>
            <Grid.Column floated="left" width={8}>
              <Header as="h3">Linked Supply Units</Header>
            </Grid.Column>
            <Grid.Column width={8} />
          </Grid.Row>
        </Grid>
        {supplyUnits.length > 0 && (
          <HierarchicalSupplyUnitsList
            supplyUnits={supplyUnits}
            interactable={false}
          />
        )}
        {addingSupplyUnit && (
          <AddSupplyUnitWizard
            setSupplyUnits={setSupplyUnits}
            supplyUnits={supplyUnits}
            setAddingSupplyUnit={setAddingSupplyUnit}
          />
        )}
      </Segment>
      <Divider />
      <Segment basic>
        <Grid>
          <Grid.Row>
            <Grid.Column width={8}>
              <Button onClick={prevPage} floated="left" disabled={isLoading}>
                Back
              </Button>
            </Grid.Column>
            <Grid.Column width={8}>
              <Button
                primary
                onClick={submitForm}
                floated="right"
                disabled={isLoading}
              >
                {isLoading ? <Loader active inline size="tiny" /> : "Submit"}
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    </>
  );
}
