import React, { useEffect, useState } from "react";
import {
  Button,
  Header,
  Icon,
  Label,
  List,
  Modal,
  Grid,
  Divider,
  Input,
  Loader,
  Segment,
  Dimmer,
} from "semantic-ui-react";
import axios from "../../../../../../apis/dvdlocator";
import { useDispatch } from "react-redux";
import { addNotification } from "../../../../../../redux/actions/notificationActions";
import { removeHtmlFromString } from "../../../../../utilities/removeHtmlFromString";
import AddFamilySelect from "./AddFamilySelect";
import DeleteFamilySelect from "./DeleteFamilySelect";
import FamilyPanel from "./FamilyPanel";

const FamilyManagement = ({ open, close, selectedRows }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [deleteSubmitting, setDeleteSubmitting] = useState(false);
  const [editSubmitting, setEditSubmitting] = useState(false);
  const [products, setProducts] = useState([]);
  const [supplyUnits, setSupplyUnits] = useState([]);
  const [editingId, setEditingId] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [addingAll, setAddingAll] = useState(false);
  const [newPartNumber, setNewPartNumber] = useState(null);
  const [deleteSelection, setDeleteSelection] = useState({
    product: null,
    family: null,
  });
  const [deleteHoverId, setDeleteHoverId] = useState(null);
  const [familyHoverId, setFamilyHoverId] = useState(null);
  const [familyToView, setFamilyToView] = useState({ familyName: "" });
  const [modal, setModal] = useState("");

  useEffect(() => {
    const loadProductFamilies = async () => {
      try {
        const res = await axios.post("/products/families", {
          productIds: selectedRows.map((product) => product.product.yom_sku),
        });

        const productFamiliesRes = res.data;

        const productsFamilyData = [];
        const supplyUnitsFamilyData = [];

        selectedRows.forEach((product) => {
          const id = product.product.yom_sku;

          const index = productFamiliesRes.findIndex(
            (item) => item.productId === id
          );

          if (index === -1) return;

          if (product.supply.upc) {
            supplyUnitsFamilyData.push({
              ...productFamiliesRes[index],
              title: removeHtmlFromString(product.supply.supply_title),
              upc: product.supply.upc,
              partNumber: product.product_families_view.part_number,
            });
          } else {
            productsFamilyData.push({
              ...productFamiliesRes[index],
              title: removeHtmlFromString(product.product.title),
              partNumber: product.product_families_view.part_number,
            });
          }
        });

        setSupplyUnits(supplyUnitsFamilyData);
        setProducts(productsFamilyData);
      } catch (err) {
        dispatch(
          addNotification({
            text: `Error loading families.`,
            icon: <Icon color="red" name="warning circle" />,
          })
        );
      } finally {
        setLoading(false);
      }
    };

    setLoading(true);
    loadProductFamilies();
  }, [selectedRows, dispatch]);

  const openFamilyPanel = (family) => {
    setFamilyToView(family);
    setModal("familyPanel");
  };

  const getAllUniqueProductFamilies = () => {
    const families = [];

    products.forEach((product) => {
      product.families.forEach((fam) => {
        const id = fam.familyId;

        if (!families.some((entry) => entry.familyId === id))
          families.push(fam);
      });
    });

    return families;
  };

  const saveSupplyPartChange = async (id) => {
    setEditSubmitting(true);
    const partNumber = newPartNumber;
    try {
      await axios.put(`/families/*/members/${id}`, {
        partNumber,
      });

      setSupplyUnits((prev) =>
        prev.map((item) => {
          if (item.productId === id) return { ...item, partNumber };
          else return item;
        })
      );

      dispatch(
        addNotification({
          text: `Saved new part number!`,
          icon: <Icon color="green" name="checkmark box" />,
        })
      );
    } catch (err) {
      console.log(err);
      dispatch(
        addNotification({
          text: `Error saving new part number.`,
          icon: <Icon color="red" name="warning circle" />,
        })
      );
    } finally {
      setEditingId(null);
      setNewPartNumber(null);
      setEditSubmitting(false);
    }
  };

  const savePartChange = async (id) => {
    setEditSubmitting(true);
    const partNumber = newPartNumber;
    try {
      await axios.put(`/families/*/members/${id}`, {
        partNumber,
      });

      setProducts((prev) =>
        prev.map((item) => {
          if (item.productId === id) return { ...item, partNumber };
          else return item;
        })
      );

      dispatch(
        addNotification({
          text: `Saved new part number!`,
          icon: <Icon color="green" name="checkmark box" />,
        })
      );
    } catch (err) {
      console.log(err);
      dispatch(
        addNotification({
          text: `Error saving new part number.`,
          icon: <Icon color="red" name="warning circle" />,
        })
      );
    } finally {
      setEditingId(null);
      setNewPartNumber(null);
      setEditSubmitting(false);
    }
  };

  const addProductsToFamily = async (familyId, familyName) => {
    let productIds = addingAll
      ? [
          ...products.map((product) => product.productId),
          ...supplyUnits.map((supplyUnit) => supplyUnit.productId),
        ]
      : [selectedProduct.productId];

    // Filtering out products that already have this family
    productIds = productIds.filter((id) => {
      let product = products.find((product) => product.productId === id);
      if (!product) {
        product = supplyUnits.find((unit) => unit.productId === id);
      }

      if (product.families.some((fam) => fam.familyId === familyId)) {
        return false;
      }
      return true;
    });

    if (productIds.length < 1) {
      dispatch(
        addNotification({
          text: `Item(s) already belong to family.`,
          icon: <Icon color="yellow" name="warning circle" />,
        })
      );
      return setModal("");
    }

    try {
      // Adding all products/supply units to pfp table
      await axios.post(`/families/${familyId}/members`, { productIds });

      // Creating associations for supply units in addition to normal pfp associations
      const upcs = [];

      productIds.forEach((id) => {
        const unit = supplyUnits.find((unit) => unit.productId === id);
        if (unit) upcs.push(unit.upc); // getting UPC for supply unit
      });

      if (upcs.length > 0) {
        await axios.post(`/families/${familyId}/supply`, {
          upcs,
        });
      }

      // Updating UI manually to show change
      setProducts((prev) =>
        prev.map((product) => {
          if (productIds.includes(product.productId))
            return {
              ...product,
              families: [...product.families, { familyId, familyName }],
            };
          else return product;
        })
      );

      setSupplyUnits((prev) =>
        prev.map((supplyUnit) => {
          if (productIds.includes(supplyUnit.productId))
            return {
              ...supplyUnit,
              families: [...supplyUnit.families, { familyId, familyName }],
            };
          else return supplyUnit;
        })
      );

      dispatch(
        addNotification({
          text: addingAll
            ? `Added family to all items!`
            : `Added family to product!`,
          icon: <Icon color="green" name="checkmark box" />,
        })
      );
    } catch (err) {
      console.log(err);
      dispatch(
        addNotification({
          text: `Error adding family to items(s).`,
          icon: <Icon color="red" name="warning circle" />,
        })
      );
    } finally {
      setModal("");
    }
  };

  const removeFamilyAll = async (familyId) => {
    const productIds = [
      ...products // dropping in relevant product IDs
        .filter((product) =>
          product.families.some((fam) => fam.familyId === familyId)
        )
        .map((product) => product.productId),
      ...supplyUnits // dropping in relevant supply unit IDs
        .filter((product) =>
          product.families.some((fam) => fam.familyId === familyId)
        )
        .map((product) => product.productId),
    ];

    try {
      for (const productId of productIds) {
        await axios.delete(`/families/${familyId}/members/${productId}`);

        // Removing join_supply_family association
        const supplyUnit = supplyUnits.find(
          (unit) => unit.productId === productId
        );
        if (supplyUnit) {
          await axios.delete(`/families/${familyId}/supply/${supplyUnit.upc}`);
        }
      }

      setProducts((prev) =>
        prev.map((item) => {
          if (productIds.includes(item.productId))
            return {
              ...item,
              families: item.families.filter(
                (fam) => fam.familyId !== familyId
              ),
            };
          else return item;
        })
      );

      setSupplyUnits((prev) =>
        prev.map((item) => {
          if (productIds.includes(item.productId))
            return {
              ...item,
              families: item.families.filter(
                (fam) => fam.familyId !== familyId
              ),
            };
          else return item;
        })
      );

      dispatch(
        addNotification({
          text: "Removed family from products!",
          icon: <Icon color="green" name="checkmark box" />,
        })
      );
    } catch (err) {
      console.log(err);
      dispatch(
        addNotification({
          text: `Error removing family from products.`,
          icon: <Icon color="red" name="warning circle" />,
        })
      );
    } finally {
      setModal("");
    }
  };

  const removeProductFromFamily = async () => {
    setDeleteSubmitting(true);

    let productId = deleteSelection.product.productId;
    const familyId = deleteSelection.family.familyId;

    try {
      await axios.delete(`/families/${familyId}/members/${productId}`);

      setProducts((prev) =>
        prev.map((item) => {
          if (item.productId === productId)
            return {
              ...item,
              families: item.families.filter(
                (fam) => fam.familyId !== familyId
              ),
            };
          else return item;
        })
      );

      dispatch(
        addNotification({
          text: `Removed family from items(s)!`,
          icon: <Icon color="green" name="checkmark box" />,
        })
      );
    } catch (err) {
      dispatch(
        addNotification({
          text: `Error removing family from items(s).`,
          icon: <Icon color="red" name="warning circle" />,
        })
      );
    } finally {
      setModal("");
      setDeleteSubmitting(false);
    }
  };

  const removeSupplyFromFamily = async () => {
    setDeleteSubmitting(true);
    const productId = deleteSelection.supplyUnit.productId;
    const upc = deleteSelection.supplyUnit.upc;
    const familyId = deleteSelection.family.familyId;

    try {
      await axios.delete(`/families/${familyId}/members/${productId}`);
      await axios.delete(`/families/${familyId}/supply/${upc}`);

      setSupplyUnits((prev) =>
        prev.map((item) => {
          if (item.productId === productId)
            return {
              ...item,
              families: item.families.filter(
                (fam) => fam.familyId !== familyId
              ),
            };
          else return item;
        })
      );

      dispatch(
        addNotification({
          text: `Removed family from supply unit(s)!`,
          icon: <Icon color="green" name="checkmark box" />,
        })
      );
    } catch (err) {
      dispatch(
        addNotification({
          text: `Error removing family from supply unit(s).`,
          icon: <Icon color="red" name="warning circle" />,
        })
      );
    } finally {
      setModal("");
      setDeleteSubmitting(false);
    }
  };

  const deleteFamilyFromState = (id) => {
    setProducts((prev) =>
      prev.map((product) => ({
        ...product,
        families: product.families.filter((fam) => fam.familyId !== id),
      }))
    );
  };

  const updateFamilyName = (id, familyName) => {
    setFamilyToView({ familyName, familyId: id });

    setProducts((prev) =>
      prev.map((product) => {
        if (!product.families.some((fam) => fam.familyId === id))
          return product;

        return {
          ...product,
          families: product.families.map((fam) => {
            if (fam.familyId === id) return { ...fam, familyName };

            return fam;
          }),
        };
      })
    );
  };

  if (loading)
    return (
      <Modal open={open} onClose={close} size="small">
        <Modal.Header>Edit Product Families</Modal.Header>
        <Modal.Content>
          <Segment padded="very">
            <Dimmer active inverted>
              <Loader active inline="centered" content="Loading" />
            </Dimmer>
          </Segment>
        </Modal.Content>
      </Modal>
    );

  if (modal === "add")
    return (
      <AddFamilySelect
        open={modal === "add"}
        close={() => setModal("")}
        addingAll={addingAll}
        add={addProductsToFamily}
      />
    );

  if (modal === "deleteAll")
    return (
      <DeleteFamilySelect
        open={modal === "deleteAll"}
        families={getAllUniqueProductFamilies()}
        close={() => setModal("")}
        remove={removeFamilyAll}
      />
    );

  if (modal === "delete")
    return (
      <Modal open={modal === "delete"} size="tiny">
        <Modal.Header>Remove family association</Modal.Header>
        <Modal.Content>
          <p>
            Would you like to remove family:{" "}
            <strong>
              {deleteSelection.family && deleteSelection.family.familyName}
            </strong>{" "}
            from product:{" "}
            <strong>
              {deleteSelection.product && deleteSelection.product.title}
            </strong>
            ?
          </p>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Button onClick={() => setModal("")}>Cancel</Button>
            <Button
              color="red"
              disabled={deleteSubmitting}
              onClick={removeProductFromFamily}
            >
              Delete
            </Button>
          </div>
        </Modal.Content>
      </Modal>
    );

  if (modal === "deleteSupply")
    return (
      <Modal open={modal === "deleteSupply"} size="tiny">
        <Modal.Header>Remove family association</Modal.Header>
        <Modal.Content>
          <p>
            Would you like to remove family:{" "}
            <strong>
              {deleteSelection.family && deleteSelection.family.familyName}
            </strong>{" "}
            from supply unit:{" "}
            <strong>
              {deleteSelection.supplyUnit && deleteSelection.supplyUnit.title}
            </strong>
            ?
          </p>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Button onClick={() => setModal("")}>Cancel</Button>
            <Button
              color="red"
              disabled={deleteSubmitting}
              onClick={removeSupplyFromFamily}
            >
              Delete
            </Button>
          </div>
        </Modal.Content>
      </Modal>
    );

  if (modal === "familyPanel")
    return (
      <FamilyPanel
        close={() => setModal("")}
        open={modal === "familyPanel"}
        family={familyToView}
        updateFamilyName={updateFamilyName}
        deleteFamilyFromState={deleteFamilyFromState}
      />
    );

  return (
    <Modal open={open} onClose={close} size="small">
      <Modal.Header>
        <Grid columns={2}>
          <Grid.Column floated="left" verticalAlign="middle">
            <Header floated="left">Edit Product Families</Header>
          </Grid.Column>
          <Grid.Column floated="right">
            <Grid columns={2}>
              <Grid.Column>
                <Button
                  icon
                  size="small"
                  color="red"
                  labelPosition="left"
                  title="Remove all products from a family"
                  onClick={() => {
                    setModal("deleteAll");
                  }}
                >
                  <Icon name="minus square" />
                  Family All
                </Button>
              </Grid.Column>
              <Grid.Column>
                <Button
                  icon
                  size="small"
                  color="green"
                  labelPosition="left"
                  title="Add all products to a family"
                  onClick={() => {
                    setModal("add");
                    setAddingAll(true);
                  }}
                >
                  <Icon name="plus square" />
                  Family All
                </Button>
              </Grid.Column>
            </Grid>
          </Grid.Column>
        </Grid>
      </Modal.Header>

      <Modal.Content scrolling>
        <Header size="medium">Products</Header>
        {products.length > 0 ? (
          <List relaxed divided>
            {products.map((product, i) => (
              <List.Item
                key={product.productId}
                style={
                  i % 2 === 0
                    ? { backgroundColor: "#e8e8e8", padding: "1em" }
                    : { padding: "1em" }
                }
              >
                <Grid columns={2}>
                  <Grid.Column width={12}>
                    <List.Content>
                      <p>
                        <strong>Title:</strong> {product.title}
                      </p>

                      <Grid columns={2} style={{ marginBottom: "0" }}>
                        <Grid.Column width={6}>
                          <p>
                            <strong>Part Number:</strong>
                          </p>
                        </Grid.Column>
                        <Grid.Column width={10}>
                          {editingId === product.productId ? (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "0.5em",
                              }}
                            >
                              <Input
                                size="mini"
                                focus
                                disabled={editSubmitting}
                                onKeyDown={(e) => {
                                  if (e.key === "Enter")
                                    savePartChange(product.productId);
                                }}
                                onChange={(e) =>
                                  setNewPartNumber(e.target.value)
                                }
                                value={newPartNumber}
                              />
                              <Icon
                                name="check"
                                color="green"
                                disabled={editSubmitting}
                                onClick={() =>
                                  savePartChange(product.productId)
                                }
                                style={{ cursor: "pointer" }}
                                title="Save"
                              />
                              <Icon
                                name="times"
                                color="red"
                                disabled={editSubmitting}
                                onClick={() => setEditingId(null)}
                                style={{ cursor: "pointer" }}
                                title="Cancel"
                              />
                            </div>
                          ) : (
                            <p>
                              {product.partNumber}{" "}
                              <Icon
                                disabled={product.families.length === 0}
                                onClick={() => {
                                  setNewPartNumber(product.partNumber);
                                  setEditingId(product.productId);
                                }}
                                name="edit outline"
                                style={
                                  product.families.length === 0
                                    ? {}
                                    : { cursor: "pointer" }
                                }
                                title={
                                  product.families.length === 0
                                    ? "Not editable- product not in a family"
                                    : "Edit part number"
                                }
                              />
                            </p>
                          )}
                        </Grid.Column>
                      </Grid>
                      <p>
                        <strong style={{ paddingRight: "8px" }}>
                          Families:
                        </strong>{" "}
                        {product.families.map((family) => (
                          <Label
                            size="medium"
                            className={`${
                              familyHoverId ===
                                `family-label-${product.productId}-${family.familyId}` &&
                              "family-label-hovered"
                            }`}
                            key={family.familyId}
                            style={{
                              background: "#f7f7f7",
                              marginRight: "8px",
                              transition: "all 0.2s ease",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <span
                                onClick={() => openFamilyPanel(family)}
                                id={`family-label-${product.productId}-${family.familyId}`}
                                onMouseEnter={(e) =>
                                  setFamilyHoverId(e.target.id)
                                }
                                onMouseLeave={() => setFamilyHoverId(null)}
                                title="View family panel and options"
                                style={{ cursor: "pointer" }}
                              >
                                {family.familyName}
                              </span>
                              <Icon
                                circular
                                id={`delete-icon-${product.productId}-${family.familyId}`}
                                name="trash"
                                color="red"
                                title="Remove this product from this family"
                                onMouseEnter={(e) =>
                                  setDeleteHoverId(e.target.id)
                                }
                                onMouseLeave={() => setDeleteHoverId(null)}
                                inverted={
                                  deleteHoverId !==
                                  `delete-icon-${product.productId}-${family.familyId}`
                                }
                                style={{ cursor: "pointer", marginLeft: "8px" }}
                                onClick={() => {
                                  setDeleteSelection({ family, product });
                                  setModal("delete");
                                }}
                              />
                            </div>
                          </Label>
                        ))}
                      </p>
                    </List.Content>
                  </Grid.Column>
                  <Grid.Column floated="right" verticalAlign="middle" width={4}>
                    <Button
                      icon
                      labelPosition="left"
                      floated="right"
                      size="tiny"
                      color="green"
                      title="Add this product to a family"
                      onClick={() => {
                        setModal("add");
                        setSelectedProduct(product);
                        setAddingAll(false);
                      }}
                    >
                      <Icon name="plus square" />
                      Family
                    </Button>
                  </Grid.Column>
                </Grid>
              </List.Item>
            ))}
          </List>
        ) : (
          <i>No products selected..</i>
        )}
        <Header size="medium">Supply Units</Header>
        {supplyUnits.length > 0 ? (
          <List relaxed divided>
            {supplyUnits.map((supplyUnit, i) => (
              <List.Item
                key={supplyUnit.productId}
                style={
                  i % 2 === 0
                    ? { backgroundColor: "#e8e8e8", padding: "1em" }
                    : { padding: "1em" }
                }
              >
                <Grid columns={2}>
                  <Grid.Column width={12}>
                    <List.Content>
                      <p>
                        <strong>Title:</strong> {supplyUnit.title}
                      </p>
                      <p>
                        <strong>UPC:</strong> {supplyUnit.upc}
                      </p>
                      <Grid columns={2} style={{ marginBottom: "0" }}>
                        <Grid.Column width={6}>
                          <p>
                            <strong>Part Number:</strong>
                          </p>
                        </Grid.Column>
                        <Grid.Column width={10}>
                          {editingId === supplyUnit.productId ? (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "0.5em",
                              }}
                            >
                              <Input
                                size="mini"
                                focus
                                disabled={editSubmitting}
                                onKeyDown={(e) => {
                                  if (e.key === "Enter")
                                    saveSupplyPartChange(supplyUnit.productId);
                                }}
                                onChange={(e) =>
                                  setNewPartNumber(e.target.value)
                                }
                                value={newPartNumber}
                              />
                              <Icon
                                name="check"
                                color="green"
                                disabled={editSubmitting}
                                onClick={() =>
                                  saveSupplyPartChange(supplyUnit.productId)
                                }
                                style={{ cursor: "pointer" }}
                                title="Save"
                              />
                              <Icon
                                name="times"
                                color="red"
                                disabled={editSubmitting}
                                onClick={() => setEditingId(null)}
                                style={{ cursor: "pointer" }}
                                title="Cancel"
                              />
                            </div>
                          ) : (
                            <p>
                              {supplyUnit.partNumber}{" "}
                              <Icon
                                disabled={supplyUnit.families.length === 0}
                                onClick={() => {
                                  setNewPartNumber(supplyUnit.partNumber);
                                  setEditingId(supplyUnit.productId);
                                }}
                                name="edit outline"
                                style={
                                  supplyUnit.families.length === 0
                                    ? {}
                                    : { cursor: "pointer" }
                                }
                                title={
                                  supplyUnit.families.length === 0
                                    ? "Not editable- supply unit not in a family"
                                    : "Edit part number"
                                }
                              />
                            </p>
                          )}
                        </Grid.Column>
                      </Grid>
                      <p>
                        <strong style={{ paddingRight: "8px" }}>
                          Families:
                        </strong>{" "}
                        {supplyUnit.families.map((family) => (
                          <Label
                            size="medium"
                            className={`${
                              familyHoverId ===
                                `family-label-${supplyUnit.productId}-${family.familyId}` &&
                              "family-label-hovered"
                            }`}
                            key={family.familyId}
                            style={{
                              background: "#f7f7f7",
                              marginRight: "8px",
                              transition: "all 0.2s ease",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <span
                                onClick={() => openFamilyPanel(family)}
                                id={`family-label-${supplyUnit.productId}-${family.familyId}`}
                                onMouseEnter={(e) =>
                                  setFamilyHoverId(e.target.id)
                                }
                                onMouseLeave={() => setFamilyHoverId(null)}
                                title="View family panel and options"
                                style={{ cursor: "pointer" }}
                              >
                                {family.familyName}
                              </span>
                              <Icon
                                circular
                                id={`delete-icon-${supplyUnit.productId}-${family.familyId}`}
                                name="trash"
                                color="red"
                                title="Remove this supply unit from this family"
                                onMouseEnter={(e) =>
                                  setDeleteHoverId(e.target.id)
                                }
                                onMouseLeave={() => setDeleteHoverId(null)}
                                inverted={
                                  deleteHoverId !==
                                  `delete-icon-${supplyUnit.productId}-${family.familyId}`
                                }
                                style={{ cursor: "pointer", marginLeft: "8px" }}
                                onClick={() => {
                                  setDeleteSelection({ family, supplyUnit });
                                  setModal("deleteSupply");
                                }}
                              />
                            </div>
                          </Label>
                        ))}
                      </p>
                    </List.Content>
                  </Grid.Column>
                  <Grid.Column floated="right" verticalAlign="middle" width={4}>
                    <Button
                      icon
                      labelPosition="left"
                      floated="right"
                      size="tiny"
                      color="green"
                      title="Add this supply unit to a family"
                      onClick={() => {
                        setModal("add");
                        setSelectedProduct(supplyUnit);
                        setAddingAll(false);
                      }}
                    >
                      <Icon name="plus square" />
                      Family
                    </Button>
                  </Grid.Column>
                </Grid>
              </List.Item>
            ))}
          </List>
        ) : (
          <i>No supply units selected..</i>
        )}
      </Modal.Content>
      <Divider />
      <Modal.Content>
        <Grid columns={2}>
          <Grid.Column>
            <Button onClick={close}>Cancel</Button>
          </Grid.Column>

          <Grid.Column>
            <Button primary floated="right" onClick={close}>
              Done
            </Button>
          </Grid.Column>
        </Grid>
      </Modal.Content>
    </Modal>
  );
};

export default FamilyManagement;
