import React, { useEffect, useRef, useState } from "react";
import { object, string } from "yup";
import { Formik, FieldArray, Field } from "formik";
import {
  Form,
  Header,
  Grid,
  Button,
  Table,
  Segment,
  Input,
  Divider,
  Message,
  Dimmer,
  Loader,
} from "semantic-ui-react";
import axios from "../../../../apis/dvdlocator";

export function InventoryTitlePage({
  supplyUnits,
  setSupplyUnits,
  prevPage,
  closeForm,
}) {
  const firstInputRef = useRef(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState(null);

  // Filter and group supply units by inventory_id AND part_number
  const groupedSupplyUnits = supplyUnits
    .filter((unit) => unit.inventory_id < 0)
    .reduce((acc, unit) => {
      const key = `${unit.inventory_id}#${unit.part_number}`;
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(unit);
      return acc;
    }, {});

  const validationSchema = object({
    inventoryTitles: object().shape(
      Object.keys(groupedSupplyUnits).reduce((acc, key) => {
        acc[key] = string().required("Inventory title is required");
        return acc;
      }, {})
    ),
  });

  const initialValues = {
    inventoryTitles: Object.keys(groupedSupplyUnits).reduce((acc, key) => {
      const firstUnit = groupedSupplyUnits[key][0];
      acc[key] = firstUnit.supply_title || "";
      return acc;
    }, {}),
  };

  const handleSubmit = async (values) => {
    setIsProcessing(true);
    setError(null);

    const updatedSupplyUnits = supplyUnits.map((unit) => {
      if (unit.inventory_id < 0) {
        const key = `${unit.inventory_id}#${unit.part_number}`;
        return {
          ...unit,
          inventory_title: values.inventoryTitles[key],
        };
      }
      return unit;
    });

    try {
      const res = await axios.post(`/supply/link-inventory`, {
        supplyUnits: updatedSupplyUnits,
      });

      setSupplyUnits((prev) => [...prev, ...res.data.supplyUnits]);
      closeForm();
    } catch (err) {
      setError(
        err.response?.data?.message ||
          "An error occurred while linking supply units. Please try again."
      );
    } finally {
      setIsProcessing(false);
    }
  };

  useEffect(() => {
    if (firstInputRef.current) {
      firstInputRef.current.focus();
    }
  }, []);

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ errors, handleSubmit, submitCount }) => (
          <Form onSubmit={handleSubmit}>
            {error && (
              <Message
                error
                header="Error"
                content={error}
                onDismiss={() => setError(null)}
              />
            )}

            <Header as="h3">Specify Inventory Titles</Header>
            {Object.entries(groupedSupplyUnits).length === 0 ? (
              <p>
                No new inventory pools were created. Click finish to proceed!
              </p>
            ) : (
              <p>
                Please provide inventory titles for groups of supply units in
                new pool groups.
              </p>
            )}

            <Dimmer.Dimmable as={Segment} dimmed={isProcessing}>
              <Dimmer active={isProcessing} inverted>
                <Loader>Linking supply units...</Loader>
              </Dimmer>

              <FieldArray name="inventoryTitles">
                {() => (
                  <Segment basic>
                    {Object.entries(groupedSupplyUnits).map(
                      ([key, units], i) => {
                        const [, partNumber] = key.split("#");
                        return (
                          <div key={key}>
                            <Header as="h4">Part #: {partNumber}</Header>
                            <Field name={`inventoryTitles.${key}`}>
                              {({ field }) => (
                                <Input
                                  {...field}
                                  fluid
                                  placeholder="Enter inventory title for this group"
                                  label="Inventory Title"
                                  ref={i === 0 ? firstInputRef : null}
                                  disabled={isProcessing}
                                />
                              )}
                            </Field>
                            <Table basic>
                              <Table.Header>
                                <Table.Row>
                                  <Table.HeaderCell>UPC</Table.HeaderCell>
                                  <Table.HeaderCell>Title</Table.HeaderCell>
                                </Table.Row>
                              </Table.Header>
                              <Table.Body>
                                {units.map((unit) => (
                                  <Table.Row key={unit.upc}>
                                    <Table.Cell>{unit.upc}</Table.Cell>
                                    <Table.Cell>{unit.supply_title}</Table.Cell>
                                  </Table.Row>
                                ))}
                              </Table.Body>
                            </Table>

                            {errors.inventoryTitles?.[key] &&
                              submitCount > 0 && (
                                <Message
                                  error
                                  content={errors.inventoryTitles[key]}
                                />
                              )}
                            <Divider hidden />
                          </div>
                        );
                      }
                    )}
                  </Segment>
                )}
              </FieldArray>
            </Dimmer.Dimmable>

            <Grid>
              <Grid.Row>
                <Grid.Column floated="left" width={5}>
                  <Button
                    type="button"
                    onClick={prevPage}
                    disabled={isProcessing}
                  >
                    Back
                  </Button>
                </Grid.Column>
                <Grid.Column floated="right" width={5} textAlign="right">
                  <Button
                    primary
                    type="submit"
                    loading={isProcessing}
                    disabled={isProcessing}
                  >
                    Finish
                  </Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
        )}
      </Formik>
    </div>
  );
}
