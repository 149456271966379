import React from "react";
import { object, string } from "yup";
import { Formik, FieldArray, Field } from "formik";
import {
  Form,
  Header,
  Grid,
  Button,
  Table,
  Segment,
  Dropdown,
  Divider,
} from "semantic-ui-react";

export function InventoryPoolPage({
  supplyUnits,
  setSupplyUnits,
  nextPage,
  prevPage,
}) {
  const groupedSupplyUnits = supplyUnits.reduce((acc, unit) => {
    if (!acc[unit.part_number]) {
      acc[unit.part_number] = [];
    }
    acc[unit.part_number].push(unit);
    return acc;
  }, {});

  const getInventoryGroupOptions = (partNumber) => {
    const options = [];

    const unitsWithSamePartNumber = groupedSupplyUnits[partNumber] || [];

    let newGroupIdIncrementer = -1;

    unitsWithSamePartNumber
      .filter((unit) => unit.inventory_id)
      .forEach((unit) => {
        if (!options.some((opt) => opt.value === unit.inventory_id)) {
          options.push({
            text: unit.inventory_title,
            value: unit.inventory_id,
            key: unit.inventory_id,
          });
        }
      });

    unitsWithSamePartNumber.forEach((unit) => {
      // Using negative numbers for unique IDs for possible new groups
      // These are added to the end of the dropdown options for better UX
      if (!unit.inventory_id) {
        options.push({
          text: `New Group ${Math.abs(newGroupIdIncrementer)}`,
          value: newGroupIdIncrementer,
          key: newGroupIdIncrementer,
        });
        newGroupIdIncrementer -= 1;
      }
    });

    return options;
  };

  const validationSchema = object({
    supplyUnits: object().shape(
      supplyUnits.reduce((acc, unit) => {
        acc[unit.upc] = object({
          inventory_id: string().required("Inventory group is required"),
        });
        return acc;
      }, {})
    ),
  });

  return (
    <div>
      <Formik
        initialValues={{
          supplyUnits: supplyUnits.reduce((acc, unit) => {
            acc[unit.upc] = { ...unit };
            return acc;
          }, {}),
        }}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          setSupplyUnits(Array.from(Object.values(values.supplyUnits)));
          nextPage();
        }}
      >
        {({ errors, handleSubmit, submitCount, setFieldValue }) => (
          <Form onSubmit={handleSubmit}>
            <Header as="h3">Specify Pooled Inventory Rules</Header>
            <p>
              Supply units with the same part number are eligible to have pooled
              inventory values.
            </p>
            <FieldArray name="supplyUnits">
              {() => (
                <Segment basic>
                  {Object.entries(groupedSupplyUnits).map(
                    ([partNumber, units]) => (
                      <div key={partNumber}>
                        <Header as="h4">Part #: {partNumber}</Header>
                        <Table basic>
                          <Table.Header>
                            <Table.Row>
                              <Table.HeaderCell>UPC</Table.HeaderCell>
                              <Table.HeaderCell>Title</Table.HeaderCell>
                              <Table.HeaderCell>
                                Inventory Pool Group
                              </Table.HeaderCell>
                            </Table.Row>
                          </Table.Header>
                          <Table.Body>
                            {units.map((unit) => (
                              <Table.Row key={unit.upc}>
                                <Table.Cell>{unit.upc}</Table.Cell>
                                <Table.Cell>{unit.supply_title}</Table.Cell>
                                <Table.Cell>
                                  <Field
                                    name={`supplyUnits.${unit.upc}.inventoryGroup`}
                                  >
                                    {({ field }) => (
                                      <Dropdown
                                        {...field}
                                        id={`supplyUnits.${unit.upc}.inventoryGroup`}
                                        options={getInventoryGroupOptions(
                                          unit.part_number
                                        )}
                                        defaultValue={unit.inventory_id}
                                        disabled={unit.inventory_id !== null}
                                        placeholder="Inventory pool group"
                                        selection
                                        onChange={(_, data) => {
                                          setFieldValue(
                                            `supplyUnits.${unit.upc}.inventory_id`,
                                            data.value
                                          );
                                        }}
                                      />
                                    )}
                                  </Field>
                                  {errors.supplyUnits?.[unit.upc]
                                    ?.inventoryGroup &&
                                    submitCount > 0 && (
                                      <span style={{ color: "red" }}>
                                        {
                                          errors.supplyUnits[unit.upc]
                                            .inventoryGroup
                                        }
                                      </span>
                                    )}
                                </Table.Cell>
                              </Table.Row>
                            ))}
                          </Table.Body>
                        </Table>
                        <Divider hidden />
                      </div>
                    )
                  )}
                </Segment>
              )}
            </FieldArray>
            <Grid>
              <Grid.Row>
                <Grid.Column floated="left" width={5}>
                  <Button type="button" onClick={prevPage}>
                    Back
                  </Button>
                </Grid.Column>
                <Grid.Column floated="right" width={5} textAlign="right">
                  <Button primary type="submit">
                    Next
                  </Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
        )}
      </Formik>
    </div>
  );
}
