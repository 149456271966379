export const supplyOptions = [
  {
    key: "DTB1",
    text: "DTB1",
    value: "supply.dtb1",
  },
  {
    key: "DTB2",
    text: "DTB2",
    value: "supply.dtb2",
  },
  {
    key: "DTB3",
    text: "DTB3",
    value: "supply.dtb3",
  },
  {
    key: "Inv",
    text: "Inv",
    value: "inventory.inv",
  },
  {
    key: "ONO",
    text: "ONO",
    value: "supply.ono",
  },
  {
    key: "Alliance Distributor Price",
    text: "Alliance Distributor Price",
    value: "supply.alliance",
  },
];

export const productOptions = [
  {
    key: "30 Day Page Sales",
    text: "30 Day Page Sales",
    value: "product_listings.30_day_sales",
  },
  {
    key: "Amz Ship Qty",
    text: "Amz Ship Qty",
    value: "product.amz_ship_qty",
  },
  {
    key: "Next Ship Qty",
    text: "Next Ship Qty",
    value: "product.next_amz_ship_qty",
  },
  {
    key: "New Shipped Stock",
    text: "New Shipped Stock",
    value: "product.ship_stock",
  },
  {
    key: "Total Orders",
    text: "Total Orders",
    value: "product.total_orders",
  },

  {
    key: "MAVCG",
    text: "MAVCG",
    value: "product.master_avg_cost_of_goods",
  },
  {
    key: "MRCOG-P",
    text: "MRCOG-P",
    value: "product.replacement_cost",
  },
  {
    key: "MRCOG-IS",
    text: "MRCOG-IS",
    value: "product.replacement_cost_dist",
  },
  {
    key: "Margin",
    text: "Margin",
    value: "product.margin",
  },
  {
    key: "ROI",
    text: "ROI",
    value: "product.roi",
  },
  {
    key: "30 Day Profit",
    text: "30 Day Profit",
    value: "product_listings.30_day_profit",
  },
];
