export function renderTitle(data) {
  if (!data) return data;

  return `<div title="${data}" style="
      white-space: normal;
      width: 300px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      line-height: 1.5em;
    ">${data}</div>`;
}
