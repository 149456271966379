import React from "react";
import { object, string } from "yup";
import { Formik, FieldArray, Field } from "formik";
import {
  Form,
  Header,
  Input,
  Grid,
  Button,
  Table,
  Segment,
} from "semantic-ui-react";
import { isValidChildPartNumber } from "../validation/validateSupplyPartNumber";

export function AssignPartNumberPage({
  supplyUnits,
  setSupplyUnits,
  productPartNumber,
  nextPage,
  prevPage,
}) {
  const validationSchema = object({
    supplyUnits: object().shape(
      supplyUnits.reduce((acc, unit) => {
        acc[unit.upc] = object({
          part_number: string()
            .test("valid-child-part", function (childNumber) {
              console.log(productPartNumber);
              if (!productPartNumber)
                return this.createError({
                  message:
                    "Product must have a part number before assigning supply unit part numbers",
                });

              const isValid = isValidChildPartNumber(
                productPartNumber,
                childNumber
              );
              return isValid
                ? true
                : this.createError({
                    message: `Invalid child part number for product part number ${productPartNumber}`,
                  });
            })
            .required("Part number is required"),
        });
        return acc;
      }, {})
    ),
  });

  return (
    <div>
      <Formik
        initialValues={{
          supplyUnits: supplyUnits.reduce((acc, unit) => {
            acc[unit.upc] = { ...unit, part_number: unit.part_number || "" };
            return acc;
          }, {}),
        }}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          setSupplyUnits(Array.from(Object.values(values.supplyUnits)));
          nextPage();
        }}
      >
        {({ errors, handleSubmit, submitCount }) => (
          <Form onSubmit={handleSubmit}>
            <Header as="h3">Assign Part Numbers</Header>
            <p>
              Supply units with the same part number are eligible to have pooled
              inventory values.
            </p>
            <FieldArray name="supplyUnits">
              {() => (
                <Segment basic>
                  <Table basic>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>UPC</Table.HeaderCell>
                        <Table.HeaderCell>Title</Table.HeaderCell>
                        <Table.HeaderCell>Part Number</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {supplyUnits.map((unit) => (
                        <Table.Row key={unit.upc}>
                          <Table.Cell>{unit.upc}</Table.Cell>
                          <Table.Cell>{unit.supply_title}</Table.Cell>
                          <Table.Cell>
                            <Field name={`supplyUnits.${unit.upc}.part_number`}>
                              {({ field }) => (
                                <Input
                                  {...field}
                                  placeholder="Part #"
                                  fluid
                                  disabled={
                                    typeof unit.part_number === "string" &&
                                    unit.part_number.length > 0
                                  }
                                />
                              )}
                            </Field>
                            {errors.supplyUnits?.[unit.upc]?.part_number &&
                              submitCount > 0 && (
                                <span style={{ color: "red" }}>
                                  {errors.supplyUnits[unit.upc].part_number}
                                </span>
                              )}
                          </Table.Cell>
                        </Table.Row>
                      ))}
                    </Table.Body>
                  </Table>
                </Segment>
              )}
            </FieldArray>
            <Grid>
              <Grid.Row>
                <Grid.Column floated="left" width={5}>
                  <Button type="button" onClick={prevPage}>
                    Back
                  </Button>
                </Grid.Column>
                <Grid.Column floated="right" width={5} textAlign="right">
                  <Button primary type="submit">
                    Next
                  </Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
        )}
      </Formik>
    </div>
  );
}
