import _ from "lodash";

export const openFamiliesProduct = (datatable, colSet) => {
  const datas = datatable.rows({ selected: true }).data();

  _.each(datas, async function (row) {
    if (row["product_families_view"]["families"].length > 0) {
      let families = row["product_families_view"]["families"]
        .split(",")
        .map((fam) => fam.trim());

      _.each(
        families,
        await async function (family) {
          await new Promise((r) => setTimeout(r, 2000));
          if (family !== "") {
            if (colSet) {
              window.open(
                "/datatables/pricetool/family/" + family + "/" + colSet,
                "_blank"
              );
            } else {
              window.open("/datatables/pricetool/family/" + family, "_blank");
            }
          }
        }
      );
    }
  });
};

export const openFamiliesSupply = (datatable, colSet) => {
  const datas = datatable.rows({ selected: true }).data();

  _.each(datas, async function (row) {
    if (row["supply_families_view"]["families"].length > 0) {
      let families = row["supply_families_view"]["families"]
        .split(",")
        .map((fam) => fam.trim());

      _.each(
        families,
        await async function (family) {
          await new Promise((r) => setTimeout(r, 2000));
          if (family !== "") {
            if (colSet) {
              window.open(
                "/datatables/pricetool/family/" + family + "/" + colSet,
                "_blank"
              );
            } else {
              window.open("/datatables/pricetool/family/" + family, "_blank");
            }
          }
        }
      );
    }
  });
};

export const openInLink = (
  datatable,
  urlStart,
  tableName,
  columnName,
  urlEnd
) => {
  const datas = datatable.rows({ selected: true }).data();

  _.each(datas, (row) => {
    if (row[tableName] && row[tableName][columnName]) {
      window.open(urlStart + row[tableName][columnName] + urlEnd, "_blank");
    } else if (row["supply"]["upc"]) {
      window.open(urlStart + row["supply"]["upc"] + urlEnd, "_blank");
    }
  });
};
