/* Module for inline editing related functions */
import { getTextWidth } from "../../utilities/domFunctions";
import $ from "jquery";

export function getTdFromEvent(e) {
  const target = e.target;

  // Check if the target is already a td
  if (target.nodeName.toLowerCase() === "td") {
    return $(target); // Return as jQuery object
  }

  // If not, search for the closest parent td
  return $(target).closest("td");
}

/**
 * Gets the current inline input.
 * @returns {(object|null)}
 */
export const getInlineInput = () => {
  const inlineForms = document.querySelector(".DTE_Inline.ui.form");
  if (!inlineForms) return;
  const inlineInput = inlineForms.querySelector("input");

  return inlineInput;
};

/**
 * Sets the active inline input width.
 * Will be, at minimum, the width of the column.
 * @param {number} colWidth Column width in pixels.
 * @returns
 */
export function setActiveInlineInputWidth(colWidth, inlineInput) {
  if (!inlineInput) return;

  const contentWidth = Math.floor(getTextWidth(inlineInput)) + 40; // adding some additional padding to width

  let width = "100%";
  if (contentWidth > colWidth) width = `${contentWidth}px`; // if we need more space than column can provide

  if (contentWidth < 80 && colWidth < 80) width = "80px";
  inlineInput.style.width = width;
}
