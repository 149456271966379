/**
 * required functions
 * 1. runscrapers
 * 2. handleFTP
 * 3. calcAllDTBs
 * 4. calSelectedDTBs
 * 5. calcAutoMargin
 * 6. updateONO
 * 7. updateDamageInventory
 */
import _ from "lodash";
import dvdlocator from "../../../../apis/dvdlocator";

export const calcAllDTBs = () => {
  if (window.confirm("Are you sure you want to recalculate all dtbs?")) {
    dvdlocator.post("/calc-all-dtbs");
  }
};

export const calcAutoMargin = () => {
  if (
    window.confirm("Are you sure you want to run the full auto margin script?")
  ) {
    dvdlocator.post("/calc-auto-margin");
  }
};

export const calcSelectedDTBs = (dt) => {
  const datas = dt.rows({ selected: true }).data().toArray();

  if (datas.length === 0) {
    alert("Please select at least one row");
    return;
  }

  if (datas.some((row) => !row.supply.upc)) {
    alert("Please select only supply units.");
    return;
  }

  let upcs = [];

  _.each(datas, (row) => {
    upcs.push(row.supply.upc);
  });

  dvdlocator.post("/calc-dtbs", { upcs });
};

export const handleFTP = (reqBody) => {
  dvdlocator
    .post("/run-ftp", reqBody)
    .then((res) => console.log(res))
    .catch((err) => console.log(err));
};

export const updateONO = async () => {
  try {
    await dvdlocator.post("/update-ono");
  } catch (err) {
    console.log(err);
  }
};

export const updateDamageInventory = async () => {
  try {
    await dvdlocator.post("/inventory/damage/update-all");
  } catch (err) {
    console.log(err);
  }
};

export const runScrapers = async (dt) => {
  const datas = dt.rows({ selected: true }).data();

  if (datas.length === 0) {
    alert("Please select at least one row");
    return;
  }

  let yom_skus = [];
  let upcs = [];

  _.each(datas, (row) => {
    yom_skus.push(row.product.yom_sku);
    upcs.push(row.product.upc);
  });

  try {
    await dvdlocator.post("/scrape-prices", { yom_skus });
  } catch (err) {
    console.log(err);
  }
};
