import React, { useState, useCallback } from "react";
import {
  Header,
  Grid,
  Button,
  Table,
  Search,
  Segment,
  Icon,
  Divider,
  Message,
  Modal,
} from "semantic-ui-react";
import axios from "../../../../apis/dvdlocator";
import { debounce } from "lodash";
import "../index.css";

export function SearchAddPage({
  masterSupplyUnits,
  supplyUnits,
  setSupplyUnits,
  nextPage,
  onClose,
}) {
  const [loading, setLoading] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [searchError, setSearchError] = useState("");
  const [confirmationModal, setConfirmationModal] = useState({
    open: false,
    unit: null,
    equivalents: [],
  });

  // Create a debounced search function
  const debouncedSearch = useCallback(
    debounce(async (query) => {
      if (!query) {
        setSearchResults([]);
        setSearchError("");
        return;
      }

      setLoading(true);
      setSearchError("");

      try {
        const results = await axios.get(`/supply?q=${query}`);
        setSearchResults(results.data);
      } catch (err) {
        console.error(err);
        setSearchError(
          err.response?.data?.message ||
            "An error occurred while searching. Please try again."
        );
        setSearchResults([]);
      } finally {
        setLoading(false);
      }
    }, 500),
    []
  );

  const searchResultSemanticOptions = () => {
    return searchResults.map((unit) => ({
      title: unit.supply_title,
      id: unit.upc,
      description: unit.upc,
    }));
  };

  const getEquivalentSupplyUnits = async (inventoryId, selectedUnitUpc) => {
    try {
      const res = await axios.get(`/supply?inventoryId=${inventoryId}`);
      return res.data.filter((unit) => unit.upc !== selectedUnitUpc);
    } catch (err) {
      console.log(err);
      return [];
    }
  };

  const isAlreadySelected = (upc) => {
    return (
      supplyUnits.some((unit) => unit.upc === upc) ||
      masterSupplyUnits.some((unit) => unit.upc === upc)
    );
  };

  const handleResultSelect = async (e, { result }) => {
    if (isAlreadySelected(result.description)) {
      return;
    }
    const unit = searchResults.find((unit) => unit.upc === result.id);

    // Getting all supply units with this inventory ID
    if (unit.inventory_id) {
      const equivalents = await getEquivalentSupplyUnits(
        unit.inventory_id,
        unit.upc
      );

      if (equivalents.length > 0) {
        // Show confirmation modal with equivalent units
        setConfirmationModal({
          open: true,
          unit,
          equivalents,
        });
        return;
      }
    }

    // If no equivalents, add the unit directly
    addSupplyUnits([unit]);
  };

  const addSupplyUnits = (units) => {
    setSupplyUnits((prev) => [...prev, ...units]);
    setSearchValue("");
    setConfirmationModal({ open: false, unit: null, equivalents: [] });
  };

  const handleConfirmationAccept = () => {
    const { unit, equivalents } = confirmationModal;
    addSupplyUnits([...equivalents, unit]);
  };

  const handleConfirmationCancel = () => {
    setConfirmationModal({ open: false, unit: null, equivalents: [] });
    setSearchValue("");
  };

  const handleRemoveUnit = (upc) => {
    setSupplyUnits((prev) => prev.filter((unit) => unit.upc !== upc));
  };

  const handleSearchChange = (e, { value }) => {
    setSearchValue(value);
    debouncedSearch(value);
  };

  const customResultRenderer = (result) => {
    const isSelected = isAlreadySelected(result.description);
    return (
      <div
        style={{
          opacity: isSelected ? 0.5 : 1,
          cursor: isSelected ? "not-allowed" : "pointer",
          padding: "8px",
          color: isSelected ? "#666" : "inherit",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div>
            <div>{result.title}</div>
            <div style={{ fontSize: "0.8em", color: "#666" }}>
              UPC: {result.description}
            </div>
          </div>
          {isSelected && (
            <span style={{ fontSize: "0.8em", color: "#666" }}>
              (Already Added)
            </span>
          )}
        </div>
      </div>
    );
  };

  return (
    <div>
      <Header as="h3">Add Supply Units</Header>
      <p>Add existing supply units for this product by UPC or Title.</p>
      <Search
        fluid
        input={{ fluid: true }}
        loading={loading}
        onResultSelect={handleResultSelect}
        onSearchChange={handleSearchChange}
        results={searchResultSemanticOptions()}
        resultRenderer={customResultRenderer}
        value={searchValue}
        minCharacters={2}
        placeholder="Search by UPC or Title"
        className="scrolling-search"
      />
      {searchError && (
        <Message error content={searchError} style={{ marginTop: "5px" }} />
      )}
      {supplyUnits.length > 0 && (
        <Segment basic>
          <Table basic>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>UPC</Table.HeaderCell>
                <Table.HeaderCell>Title</Table.HeaderCell>
                <Table.HeaderCell />
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {supplyUnits.map((unit) => (
                <Table.Row key={unit.upc}>
                  <Table.Cell>{unit.upc}</Table.Cell>
                  <Table.Cell>{unit.supply_title.slice(0, 100)}</Table.Cell>
                  <Table.Cell>
                    <Icon
                      title="Remove unit from selection"
                      name="trash alternate"
                      color="red"
                      style={{ cursor: "pointer" }}
                      onClick={() => handleRemoveUnit(unit.upc)}
                    />
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Segment>
      )}
      <Divider hidden />
      <Grid>
        <Grid.Row>
          <Grid.Column floated="left" width={5}>
            <Button type="button" onClick={onClose}>
              Close
            </Button>
          </Grid.Column>
          <Grid.Column floated="right" width={5} textAlign="right">
            <Button
              primary
              type="button"
              onClick={nextPage}
              disabled={supplyUnits.length === 0}
            >
              Next
            </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <Modal open={confirmationModal.open} size="small">
        <Modal.Header>Add Equivalent Supply Units</Modal.Header>
        <Modal.Content>
          <p>
            This supply unit has {confirmationModal.equivalents.length}{" "}
            equivalent items. Adding it will add all the following supply units
            to this product:
          </p>
          <Table basic="very">
            <Table.Body>
              {confirmationModal.unit && (
                <Table.Row positive key={confirmationModal.unit.upc}>
                  <Table.Cell>{confirmationModal.unit.upc}</Table.Cell>
                  <Table.Cell>{confirmationModal.unit.supply_title}</Table.Cell>
                  <Table.Cell>{`(selected)`}</Table.Cell>
                </Table.Row>
              )}
              {confirmationModal.equivalents.map((unit) => (
                <Table.Row warning key={unit.upc}>
                  <Table.Cell>{unit.upc}</Table.Cell>
                  <Table.Cell>{unit.supply_title}</Table.Cell>
                  <Table.Cell>{`(equivalent)`}</Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={handleConfirmationCancel}>Cancel</Button>
          <Button primary onClick={handleConfirmationAccept}>
            Accept
          </Button>
        </Modal.Actions>
      </Modal>
    </div>
  );
}
