function parsePartNumber(productPartNumber) {
  // If a single number
  if (!productPartNumber.match(/[+-]/)) {
    return [productPartNumber];
  }

  // If a non-equivalent marked single number e.g: 5-z
  if (productPartNumber.match(/-z/)) {
    return [productPartNumber];
  }

  const validCodes = [];

  const groupsToProcess = productPartNumber.split("+");

  groupsToProcess.forEach((group) => {
    insertValidCodes(validCodes, group);
  });

  return validCodes;
}

// Parses and inserts the valid codes into a given array
function insertValidCodes(validCodesArray, groupToProcess) {
  if (!groupToProcess.includes("-")) {
    validCodesArray.push(groupToProcess);
    return;
  }

  // If it is a range, get low high tuple/array e.g: 1-7 -> [1, 7]
  const [low, high] = groupToProcess.split("-");

  // Push values within range to valid codes array
  for (let i = parseInt(low, 10); i <= parseInt(high, 10); i++) {
    validCodesArray.push(i.toString());
  }
}

export function isValidChildPartNumber(parentNumber, childNumber) {
  const allowedValues = parsePartNumber(parentNumber);
  const childValues = parsePartNumber(childNumber);

  // Return true only if ALL child values are included in allowed values
  return childValues.every((val) => allowedValues.includes(val));
}
